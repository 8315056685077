import React, { useEffect, useRef, useState } from 'react';
import { validatePassword, PasswordValidationResult } from './App';
import AlertModal from './components/AlertModal';
import { EyeIcon, EyeOffIcon, Loader } from 'lucide-react';
import Background from './components/Background';
import { getAGBUrl } from './supabaseClient';
import { resetPassword } from './supabaseClient';
import { signInWithGoogle } from './supabaseClient';
import { FcGoogle } from 'react-icons/fc';
import Footer from './components/Footer';
import { motion, AnimatePresence } from 'framer-motion';
import { Turnstile } from '@marsidev/react-turnstile'
import { useInternetConnection } from './hooks/useInternetConnection';

interface LandingPageProps {
  onLogin: (email: string, password: string, captchaToken: string) => Promise<void>;
  onSignUp: (email: string, password: string, captchaToken: string) => Promise<void>;
}

const LandingPage: React.FC<LandingPageProps> = ({ onLogin, onSignUp }) => {
  const parallaxRef = useRef<HTMLDivElement>(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignUp, setIsSignUp] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidationResult>({ isValid: false, errors: [] });
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [emailValidation, setEmailValidation] = useState({ isValid: true, error: '' });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [agbAccepted, setAgbAccepted] = useState(false);
  const [agbModalOpen, setAgbModalOpen] = useState(false);
  const [agbContent, setAgbContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showFeatures, setShowFeatures] = useState(true);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const { online, checkConnection } = useInternetConnection();
  const [resetCaptchaToken, setResetCaptchaToken] = useState<string | null>(null);
  const [resetCaptchaVerified, setResetCaptchaVerified] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrolled = window.scrollY;
        parallaxRef.current.style.transform = `translateY(${scrolled * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isSignUp) {
      setPasswordValidation(validatePassword(password));
    }
  }, [password, isSignUp]);

  const validateEmail = (email: string, checkRequired: boolean = false) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (checkRequired && !email) {
      return { isValid: false, error: 'E-Mail ist erforderlich' };
    }
    if (email && !emailRegex.test(email)) {
      return { isValid: false, error: 'Ungültige E-Mail-Adresse' };
    }
    return { isValid: true, error: '' };
  };

  useEffect(() => {
    if (email || isSubmitted) {
      setEmailValidation(validateEmail(email, isSubmitted));
    } else {
      setEmailValidation({ isValid: true, error: '' });
    }
  }, [email, isSubmitted]);

  const checkInternetAndShowAlert = () => {
    if (!online) {
      showAlert('Keine Internetverbindung verfügbar. Bitte überprüfe deine Verbindung und versuche es erneut.');
      return false;
    }
    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Prüfe Internetverbindung
    await checkConnection();
    if (!checkInternetAndShowAlert()) return;

    setIsSubmitted(true);
    const emailValidationResult = validateEmail(email, true);
    if (!emailValidationResult.isValid) {
      showAlert(emailValidationResult.error);
      return;
    }
    if (isSignUp) {
      if (passwordValidation.isValid && agbAccepted) {
        if (!captchaVerified || !captchaToken) {
          showAlert('Bitte bestätige, dass du ein Mensch bist.');
          return;
        }
        setIsLoading(true);
        try {
          await onSignUp(email, password, captchaToken);
          // Wenn keine Fehler aufgetreten sind, war die Registrierung erfolgreich
          setIsSignUp(false);
          setPassword('');
          setPasswordValidation({ isValid: false, errors: [] });
          setCaptchaToken(null);
          setCaptchaVerified(false);
          setAgbAccepted(false);
        } catch (error) {
          console.error('Fehler bei der Registrierung:', error);
          showAlert('Es gab einen Fehler bei der Registrierung. Bitte versuche es später erneut.');
          setCaptchaToken(null);
          setCaptchaVerified(false);
        } finally {
          setIsLoading(false);
        }
      } else {
        showAlert('Bitte überprüfe deine Eingaben und akzeptiere die AGB.');
      }
    } else {
      if (!captchaVerified || !captchaToken) {
        showAlert('Bitte bestätige, dass du ein Mensch bist.');
        return;
      }
      setIsLoading(true);
      try {
        await onLogin(email, password, captchaToken);
      } catch (error: any) {
        console.error('Fehler beim Login:', error);
        let errorMessage = 'Es gab einen Fehler beim Login. Bitte überprüfe deine Eingaben und versuche es erneut.';
        
        // Übersetze spezifische Fehlermeldungen
        if (error.message === 'Email not confirmed') {
          errorMessage = 'E-Mail-Adresse wurde noch nicht bestätigt. Bitte überprüfe dein E-Mail-Postfach.';
        }
        
        showAlert(errorMessage);
        setCaptchaToken(null);
        setCaptchaVerified(false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const showAlert = (message: string) => {
    setAlertMessage(message);
  };

  const getLabelStyle = (value: string) => {
    return `absolute left-2 transition-all duration-200 ${value ? '-top-2.5 text-xs bg-white px-1' : 'top-3 text-base'
      } text-content-light pointer-events-none`;
  };

  useEffect(() => {
    const fetchAGB = async () => {
      try {
        const url = await getAGBUrl();
        const response = await fetch(url);
        const text = await response.text();
        setAgbContent(text);
      } catch (error) {
        console.error('Fehler beim Laden der AGB:', error);
      }
    };

    fetchAGB();
  }, []);

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    
    if (!resetCaptchaVerified || !resetCaptchaToken) {
      showAlert('Bitte bestätige, dass du ein Mensch bist.');
      setIsLoading(false);
      return;
    }

    try {
      await resetPassword(resetEmail, resetCaptchaToken);
      showAlert('Eine E-Mail zum Zurücksetzen des Passworts wurde gesendet.\nBitte überprüfe deinen Posteingang.');
      setShowResetPassword(false);
      setResetEmail('');
      setResetCaptchaToken(null);
      setResetCaptchaVerified(false);
    } catch (error) {
      console.error('Fehler beim Zurücksetzen des Passworts:', error);
      showAlert('Es gab einen Fehler beim Senden der Passwort-Zurücksetzen-E-Mail.\nBitte versuche es später erneut.');
      setResetCaptchaToken(null);
      setResetCaptchaVerified(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const consentGiven = localStorage.getItem('cookieConsent');
    if (!consentGiven) {
      setShowCookieConsent(true);
    }
  }, []);

  const handleCookieConsent = () => {
    localStorage.setItem('cookieConsent', 'true');
    setShowCookieConsent(false);
  };

  const handleGoogleSignIn = async () => {
    // Prüfe Internetverbindung
    await checkConnection();
    if (!checkInternetAndShowAlert()) return;

    setIsLoading(true);
    try {
      await signInWithGoogle();
      // Nach erfolgreicher Anmeldung wird der Benutzer automatisch weitergeleitet
    } catch (error) {
      console.error('Fehler bei der Google-Anmeldung:', error);
      showAlert('Es gab einen Fehler bei der Google-Anmeldung. Bitte versuche es später erneut.');
    } finally {
      setIsLoading(false);
    }
  };

  const isLoginDisabled = () => {
    return !email || !password || !emailValidation.isValid || isLoading;
  };

  useEffect(() => {
    if (!isSignUp) {
      setCaptchaToken(null);
      setCaptchaVerified(false);
    }
  }, [isSignUp]);

  const formVariants = {
    enter: {
      x: 300,
      opacity: 0
    },
    center: {
      x: 0,
      opacity: 1
    },
    exit: {
      x: -300,
      opacity: 0
    }
  };

  return (
    <Background showAudioControls={false}>
      <div className="fixed top-0 left-0 right-0 bg-yellow-500 text-black p-4 text-center z-50">
        <p className="font-semibold">
          🚧 talebear.com befindet sich derzeit in der Closed Alpha Phase. Eine Registrierung ist momentan nicht möglich. 🚧
        </p>
      </div>
      <a
        href="#main-content"
        className="sr-only focus:not-sr-only focus:absolute focus:z-50 focus:p-4 focus:bg-white focus:text-primary-600"
      >
        Zum Hauptinhalt springen
      </a>
      <div className="flex flex-col min-h-screen">
        <header role="banner" className="flex-grow">
          <main id="main-content" role="main" className="relative z-10 flex flex-col items-center justify-center min-h-screen p-4">
            <h1 className="text-6xl font-bold text-secondary-600 mb-8">talebear.com</h1>
            <p className="text-xl text-white mb-8 text-center max-w-2xl">
              Entdecke die Magie des Geschichtenerzählens
            </p>

            {showFeatures ? (
              <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl w-full mb-8">
                <ul className="list-none pl-5 space-y-2 text-content">
                  <li><strong>KI-gestützte Geschichtengenerierung:</strong> Erstelle fesselnde Geschichten basierend auf Ihren Eingaben und Ideen.</li>
                  <li><strong>Altersgerechte Inhalte:</strong> Passe die Geschichten an verschiedene Altersgruppen an.</li>
                  <li><strong>Audio-Generierung:</strong> Verwandele deine Geschichten in Hörerlebnisse mit KI-generierter Vorlesestimme.</li>
                  <li><strong>Benutzerfreundliches Interface:</strong> Einfache Navigation und intuitive Bedienung.</li>
                  <li><strong>Sicheres Benutzerkonto:</strong> Speichere und verwalte deine Geschichten in deinem persönlichen Bereich.</li>
                </ul>
              </div>
            ) : null}

            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-2xl font-bold mb-4 text-content">
                {showResetPassword ? 'Passwort zurücksetzen' : (isSignUp ? 'Registrieren' : 'Anmelden')}
              </h2>
              <AnimatePresence mode="wait">
                <motion.div
                  key={isSignUp ? 'signup' : 'login'}
                  variants={formVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{
                    type: "spring",
                    stiffness: 300,
                    damping: 30
                  }}
                >
                  {!showResetPassword ? (
                    <>
                      <form onSubmit={handleSubmit}>
                        <div className="relative mb-4">
                          <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="border p-2 w-full pr-10 h-12 rounded-lg"
                            id="email"
                            required
                          />
                          <label htmlFor="email" className={`${getLabelStyle(email)} text-content-light`}>
                            E-Mail
                          </label>
                          {!emailValidation.isValid && (
                            <p className="text-red-500 text-xs mt-1">{emailValidation.error}</p>
                          )}
                        </div>
                        <div className="relative mb-4">
                          <input
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="border p-2 w-full pr-10 h-12 rounded-lg"
                            id="password"
                            required
                          />
                          <label htmlFor="password" className={getLabelStyle(password)}>
                            Passwort
                          </label>
                          <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-2 top-1/2 -translate-y-1/2"
                          >
                            {showPassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
                          </button>
                        </div>
                        {isSignUp && (
                          <div className="mb-4">
                            <h3 className="text-sm font-semibold mb-2">Passwortanforderungen:</h3>
                            <ul className="text-xs">
                              {['Mindestens 8 Zeichen', 'Mindestens ein Großbuchstabe', 'Mindestens ein Kleinbuchstabe', 'Mindestens eine Zahl', 'Mindestens ein Sonderzeichen'].map((requirement, index) => (
                                <li key={index} className={passwordValidation.errors.includes(requirement) ? 'text-red-500' : 'text-green-500'}>
                                  {requirement}
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                        <div className="mb-4">
                          <Turnstile
                            siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ''}
                            onSuccess={(token) => {
                              setCaptchaVerified(true);
                              setCaptchaToken(token);
                            }}
                            onError={() => {
                              setCaptchaVerified(false);
                              setCaptchaToken(null);
                            }}
                          />
                        </div>
                        {isSignUp && (
                          <div className="mb-4">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                id="agb"
                                checked={agbAccepted}
                                onChange={(e) => setAgbAccepted(e.target.checked)}
                                className="mr-2"
                              />
                              <label htmlFor="agb" className="text-sm">
                                Ich stimme den{' '}
                                <button
                                  type="button"
                                  onClick={() => setAgbModalOpen(true)}
                                  className="text-blue-500 underline"
                                >
                                  AGB
                                </button>{' '}
                                zu
                              </label>
                            </div>
                          </div>
                        )}
                        <button
                          type="submit"
                          className={`w-full p-3 rounded-lg font-semibold transition-colors mb-4 ${(isSignUp && (!passwordValidation.isValid || !captchaVerified || !agbAccepted)) ||
                              (!isSignUp && isLoginDisabled()) || isLoading
                              ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                              : 'bg-primary-500 text-white hover:bg-primary-600'
                            }`}
                          disabled={(isSignUp && (!passwordValidation.isValid || !captchaVerified || !agbAccepted)) ||
                            (!isSignUp && isLoginDisabled()) || isLoading}
                        >
                          {isLoading ? (
                            <div className="flex items-center justify-center">
                              <Loader className="animate-spin mr-2" size={20} />
                              <span>{isSignUp ? 'Registriere...' : 'Melde an...'}</span>
                            </div>
                          ) : (
                            isSignUp ? 'Registrieren' : 'Anmelden'
                          )}
                        </button>
                      </form>
                      <button
                        onClick={() => {
                          setIsSignUp(!isSignUp);
                          setPassword('');
                          setPasswordValidation({ isValid: false, errors: [] });
                          setShowFeatures(false);
                        }}
                        className="w-full bg-blue-500 text-white p-3 rounded-lg font-semibold hover:bg-blue-600 transition-colors mb-4"
                      >
                        {isSignUp ? 'Bereits ein Konto? Anmelden' : 'Kein Konto? Registrieren'}
                      </button>
                      <button
                        onClick={handleGoogleSignIn}
                        className="w-full bg-white text-content-dark p-3 rounded-lg font-semibold hover:bg-gray-100 transition-colors mb-4 flex items-center justify-center border border-gray-300"
                        disabled={isLoading}
                      >
                        <FcGoogle className="mr-2" size={20} />
                        {isLoading ? 'Wird angemeldet...' : 'Mit Google anmelden'}
                      </button>
                      <button
                        onClick={() => setShowResetPassword(true)}
                        className="text-blue-500 hover:underline text-sm"
                      >
                        Passwort vergessen?
                      </button>
                    </>
                  ) : (
                    <form onSubmit={handleResetPassword}>
                      <div className="relative mb-4">
                        <input
                          type="email"
                          value={resetEmail}
                          onChange={(e) => setResetEmail(e.target.value)}
                          className="border p-2 w-full pr-10 h-12 rounded-lg"
                          id="resetEmail"
                          required
                        />
                        <label htmlFor="resetEmail" className={getLabelStyle(resetEmail)}>
                          E-Mail für Passwort-Zurücksetzen
                        </label>
                      </div>
                      <div className="mb-4">
                        <Turnstile
                          siteKey={process.env.REACT_APP_TURNSTILE_SITE_KEY || ''}
                          onSuccess={(token) => {
                            setResetCaptchaVerified(true);
                            setResetCaptchaToken(token);
                          }}
                          onError={() => {
                            setResetCaptchaVerified(false);
                            setResetCaptchaToken(null);
                          }}
                        />
                      </div>
                      <button
                        type="submit"
                        className={`w-full p-3 rounded-lg font-semibold transition-colors mb-4 ${isLoading
                            ? 'bg-gray-400 text-gray-200 cursor-not-allowed'
                            : 'bg-blue-500 text-white hover:bg-blue-600'
                          }`}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <div className="flex items-center justify-center">
                            <Loader className="animate-spin mr-2" size={20} />
                            <span>Sende E-Mail...</span>
                          </div>
                        ) : (
                          'Passwort zurücksetzen'
                        )}
                      </button>
                      <button
                        onClick={() => setShowResetPassword(false)}
                        className="text-blue-500 hover:underline text-sm"
                      >
                        Zurück zur Anmeldung
                      </button>
                    </form>
                  )}
                </motion.div>
              </AnimatePresence>
            </div>
          </main>
        </header>
        <Footer />
      </div>
      <AlertModal
        isOpen={alertMessage !== null}
        message={alertMessage || ''}
        onClose={() => setAlertMessage(null)}
      />
      {agbModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4">Allgemeine Geschäftsbedingungen</h2>
            <div className="mb-4 whitespace-pre-wrap">{agbContent}</div>
            <button
              onClick={() => setAgbModalOpen(false)}
              className="bg-blue-500 text-white p-2 rounded"
            >
              Schließen
            </button>
          </div>
        </div>
      )}
      {showCookieConsent && (
        <div className="fixed bottom-0 left-0 right-0 bg-gray-800 bg-opacity-90 text-white p-6 flex flex-col md:flex-row justify-between items-center z-50">
          <p className="text-base mb-4 md:mb-0 md:mr-4 text-center md:text-left">
            Diese Website verwendet Cookies, um Ihr Browsererlebnis zu verbessern und anonyme Nutzungsstatistiken zu sammeln.
          </p>
          <button
            onClick={handleCookieConsent}
            className="bg-white text-content-dark px-6 py-3 rounded-lg hover:bg-gray-200 transition-colors text-base font-semibold"
          >
            Akzeptieren
          </button>
        </div>
      )}
    </Background>
  );
};

export default LandingPage;
