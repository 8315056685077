import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface PromptBuilderProps {
  onPromptGenerated: (prompt: string) => void;
  isOpen: boolean;
  onToggle: () => void;
}

interface DictionaryItem {
  name: string;
  article: string;
}

const PromptBuilder: React.FC<PromptBuilderProps> = ({ onPromptGenerated, isOpen, onToggle }) => {
  const [setting, setSetting] = useState('');
  const [mainCharacter, setMainCharacter] = useState('');
  const [sideCharacter, setSideCharacter] = useState('');
  const [plot, setPlot] = useState('');

  const settings: DictionaryItem[] = [
    { name: 'Wald', article: 'einem' },
    { name: 'Schloss', article: 'einem' },
    { name: 'Strand', article: 'einem' },
    { name: 'Weltraum', article: 'dem' },
    { name: 'Unterwasserwelt', article: 'der' }
  ];

  const mainCharacters: DictionaryItem[] = [
    { name: 'Prinzessin', article: 'einer' },
    { name: 'Ritter', article: 'einem' },
    { name: 'Zauberer', article: 'einem' },
    { name: 'Tier', article: 'einem' },
    { name: 'Roboter', article: 'einem' }
  ];

  const sideCharacters: DictionaryItem[] = [
    { name: 'Freund', article: 'Ein' },
    { name: 'Feind', article: 'Ein' },
    { name: 'magisches Wesen', article: 'Ein' },
    { name: 'weiser Mentor', article: 'Ein' },
    { name: 'lustiger Begleiter', article: 'Ein' }
  ];

  const plots: DictionaryItem[] = [
    { name: 'Suche nach einem Schatz', article: 'die' },
    { name: 'Rettung eines Freundes', article: 'die' },
    { name: 'Lösung eines Rätsels', article: 'die' },
    { name: 'Überwindung einer Herausforderung', article: 'die' },
    { name: 'Entdeckung einer neuen Welt', article: 'die' }
  ];

  const generatePrompt = () => {
    const selectedSetting = settings.find(s => s.name === setting);
    const selectedMainCharacter = mainCharacters.find(c => c.name === mainCharacter);
    const selectedSideCharacter = sideCharacters.find(c => c.name === sideCharacter);
    const selectedPlot = plots.find(p => p.name === plot);

    if (selectedSetting && selectedMainCharacter && selectedSideCharacter && selectedPlot) {
      const prompt = `Erzähle eine Geschichte in ${selectedSetting.article} ${selectedSetting.name} mit ${selectedMainCharacter.article} ${selectedMainCharacter.name} als Hauptfigur. ${selectedSideCharacter.article} ${selectedSideCharacter.name} spielt eine wichtige Nebenrolle. Die Handlung dreht sich um ${selectedPlot.article} ${selectedPlot.name}.`;
      onPromptGenerated(prompt);
    }
  };

  const renderOptions = (options: DictionaryItem[], selectedOption: string, setOption: (option: string) => void) => (
    <div className="flex flex-wrap gap-2 mb-4">
      {options.map((option) => (
        <button
          key={option.name}
          className={`px-4 py-2 rounded-full ${selectedOption === option.name ? 'bg-blue-500 text-white' : 'bg-gray-200 text-content-dark hover:bg-gray-300 transition-colors duration-200'}`}
          onClick={() => setOption(option.name)}
        >
          {option.name}
        </button>
      ))}
    </div>
  );

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4">
      <button
        className="w-full flex justify-between items-center text-base font-bold mb-4 text-content-dark"
        onClick={onToggle}
      >
        Geschichte zusammenstellen
        {isOpen ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </button>
      {isOpen && (
        <>
          <div className="mb-4">
            <h3 className="font-medium mb-2 text-content">Setting</h3>
            {renderOptions(settings, setting, setSetting)}
          </div>
          <div className="mb-4">
            <h3 className="font-medium mb-2 text-content">Hauptfigur</h3>
            {renderOptions(mainCharacters, mainCharacter, setMainCharacter)}
          </div>
          <div className="mb-4">
            <h3 className="font-medium mb-2 text-content">Nebenfigur</h3>
            {renderOptions(sideCharacters, sideCharacter, setSideCharacter)}
          </div>
          <div className="mb-4">
            <h3 className="font-medium mb-2 text-content">Handlung</h3>
            {renderOptions(plots, plot, setPlot)}
          </div>
          <button
            className="w-full bg-blue-500 text-white p-2 rounded-lg hover:bg-blue-600 transition-colors duration-200"
            onClick={generatePrompt}
            disabled={!setting || !mainCharacter || !sideCharacter || !plot}
          >
            Prompt generieren
          </button>
        </>
      )}
    </div>
  );
};

export default PromptBuilder;