import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';

interface BackgroundProps {
  children: React.ReactNode;
  showAudioControls: boolean;
}

interface WordData {
  x: number;
  y: number;
  opacity: number;
  velocityX: number;
  velocityY: number;
}

const allWords = [
  'Geschichten', 'Fantasie', 'Abenteuer', 'Märchen', 'Erzählen', 'Vorlesen', 'Kreativität',
  'Zauber', 'Helden', 'Drachen', 'Feen', 'Magie', 'Reise', 'Traum', 'Wunder', 'Entdeckung',
  'Mut', 'Freundschaft', 'Liebe', 'Weisheit', 'Rätsel', 'Geheimnis', 'Schatz', 'Legende'
];

// Memoisierte Word-Komponente für weniger Re-Renders
const FloatingWord = React.memo(({ word, style }: { word: string; style: React.CSSProperties }) => (
  <div
    className="absolute text-white pointer-events-none select-none"
    style={{
      ...style,
      left: 0,
      top: 0,
    }}
  >
    {word}
  </div>
));

FloatingWord.displayName = 'FloatingWord';

const Background: React.FC<BackgroundProps> = ({ children, showAudioControls }) => {
  const [wordsData, setWordsData] = useState<WordData[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const frameRef = useRef<number>();

  const updateWordCount = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const area = width * height;
    const wordCount = Math.floor(area / 40000);
    return Math.min(Math.max(wordCount, 5), allWords.length);
  }, []);

  const initializeWords = useCallback(() => {
    const wordCount = updateWordCount();
    const shuffled = [...allWords].sort(() => 0.5 - Math.random());
    const selectedWords = shuffled.slice(0, wordCount);

    // Erstelle ein Array von bereits verwendeten Positionen
    const usedPositions: Array<{x: number, y: number}> = [];
    
    const getRandomPosition = (): {x: number, y: number} => {
      let attempts = 0;
      const minDistance = 20; // Mindestabstand zwischen Wörtern in Prozent
      
      while (attempts < 50) {
        const x = Math.random() * 80 + 10; // 10% - 90% des Bildschirms
        const y = Math.random() * 80 + 10; // 10% - 90% des Bildschirms
        
        // Prüfe Abstand zu allen anderen Wörtern
        const isTooClose = usedPositions.some(pos => {
          const dx = pos.x - x;
          const dy = pos.y - y;
          return Math.sqrt(dx * dx + dy * dy) < minDistance;
        });
        
        if (!isTooClose || attempts > 45) {
          usedPositions.push({x, y});
          return {x, y};
        }
        
        attempts++;
      }
      
      // Fallback, falls keine gute Position gefunden wurde
      return {
        x: Math.random() * 80 + 10,
        y: Math.random() * 80 + 10
      };
    };

    const newWordsData = selectedWords.map(() => {
      const {x, y} = getRandomPosition();
      const angle = Math.random() * Math.PI * 2; // Zufälliger Winkel
      const speed = 0.05 + Math.random() * 0.05; // Konstante Geschwindigkeit
      
      return {
        x,
        y,
        opacity: 0.2 + Math.random() * 0.3, // Zwischen 0.2 und 0.5
        velocityX: Math.cos(angle) * speed,
        velocityY: Math.sin(angle) * speed,
      };
    });

    setWordsData(newWordsData);
  }, [updateWordCount]);

  const updatePositions = useCallback(() => {
    setWordsData(prev => prev.map(word => {
      let newX = word.x + word.velocityX;
      let newY = word.y + word.velocityY;
      let newVelocityX = word.velocityX;
      let newVelocityY = word.velocityY;

      // Sanftere Reflexion an den Rändern
      if (newX < 0 || newX > 100) {
        newVelocityX = -newVelocityX * 0.95; // Leichte Dämpfung
        newX = newX < 0 ? 0 : 100;
      }

      if (newY < 0 || newY > 100) {
        newVelocityY = -newVelocityY * 0.95; // Leichte Dämpfung
        newY = newY < 0 ? 0 : 100;
      }

      // Sanftere Opazitätsänderung
      const opacityChange = (Math.random() - 0.5) * 0.01;
      let newOpacity = word.opacity + opacityChange;
      newOpacity = Math.max(0.2, Math.min(0.5, newOpacity));

      return {
        ...word,
        x: newX,
        y: newY,
        opacity: newOpacity,
        velocityX: newVelocityX,
        velocityY: newVelocityY,
      };
    }));

    frameRef.current = requestAnimationFrame(updatePositions);
  }, []);

  useEffect(() => {
    initializeWords();
    window.addEventListener('resize', initializeWords);

    frameRef.current = requestAnimationFrame(updatePositions);

    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
      window.removeEventListener('resize', initializeWords);
    };
  }, [initializeWords, updatePositions]);

  const wordStyles = useMemo(() => 
    wordsData.map((wordData) => ({
      transform: `translate(${wordData.x}vw, ${wordData.y}vh)`,
      fontSize: '4rem',
      opacity: wordData.opacity,
      willChange: 'transform, opacity',
    })), [wordsData]);

  return (
    <div
      ref={containerRef}
      className={`bg-gradient-to-r from-secondary-400 via-primary-400 to-primary-500 min-h-screen p-4 relative overflow-hidden ${
        showAudioControls ? 'pb-32' : ''
      }`}
    >
      <div className="absolute inset-0 overflow-hidden" aria-hidden="true">
        {wordStyles.map((style, index) => (
          <FloatingWord
            key={allWords[index]}
            word={allWords[index]}
            style={style}
          />
        ))}
      </div>
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
};

export default React.memo(Background);