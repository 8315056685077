import React, { useState, useEffect, useRef, useCallback } from 'react';
import { BookOpen, Award, Send, Loader, Volume2, Play, Trash2, Settings, LogOutIcon, CreditCard, Menu, Coins, MessageSquare, ArrowUpZA, ArrowDownAZ, CalendarArrowDown, CalendarArrowUp, X, Image, History, Mic, MicOff } from 'lucide-react';
import { supabase, Tale, saveTale, getTales, updateTale, deleteTale, getAudioUrl, getBadWords, getUserSubscription, getUserCredits, UserSubscription, shouldRefillCredits, refillCredits, getCreditsPerRequest, getStoriesLimit, getStoryLengthOptions } from './supabaseClient';
import ProfileModal from './ProfileModal';
import AlertModal from './components/AlertModal';
import { filterContent, updateBadWords } from './utils/contentFilter';
import { updateFilteredTaleContent, calculateContentHash } from './supabaseClient';
import Background from './components/Background';
import PromptBuilder from './components/PromptBuilder';
import AudioPlayer from './components/AudioPlayer';
import SubscriptionModal from './components/SubscriptionModal';
import Footer from './components/Footer';
import FeedbackModal from './components/FeedbackModal';
import TransactionHistory from './components/TransactionHistory';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

interface TaleBearProps {
  onLogout: () => void;
}

// Neue Interface für SpeechRecognition
interface IWindow extends Window {
  webkitSpeechRecognition: any;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface CreditsPerRequest {
  generate_story: number;
  generate_audio: number;
  generate_image: number;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface StoryLength {
  type: string;
  label: string;
  credits: number;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface CreativityLevel {
  type: string;
  label: string;
  temperature: number;
}

// Fügen Sie diese neue Interface hinzu
interface LLMModel {
  id: string;
  name: string;
  description: string;
  maxTokens: number;
}

// Fügen Sie diese neue Schnittstelle hinzu
interface SortConfig {
  key: 'title' | 'created_at';
  direction: 'asc' | 'desc';
}

// Fügen Sie diese neue Komponente am Anfang der Datei hinzu
const StoryLengthSwitch: React.FC<{
  options: StoryLength[];
  value: string;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  return (
    <div className="flex items-center space-x-1 bg-gray-100 p-1 rounded-lg w-full">
      <div className="grid grid-cols-3 gap-1 w-full">
        {options.map((option) => (
          <button
            key={option.type}
            onClick={() => onChange(option.type)}
            className={`
              px-3 py-1.5 text-xs font-medium rounded-md transition-all duration-200
              flex items-center justify-center whitespace-nowrap w-full
              ${value === option.type
                ? 'bg-white text-emerald-600 shadow-sm'
                : 'text-content-light hover:bg-gray-200'
              }
            `}
          >
            {option.label}
            <span className="ml-1 text-xs opacity-75 flex items-center">
              <Coins size={12} className="mr-0.5" />
              {option.credits}
            </span>
          </button>
        ))}
      </div>
    </div>
  );
};

// Fügen Sie diese neue Komponente hinzu
const CreativitySwitch: React.FC<{
  options: CreativityLevel[];
  value: string;
  onChange: (value: string) => void;
}> = ({ options, value, onChange }) => {
  return (
    <div className="flex items-center bg-gray-100 p-1 rounded-lg w-full">
      <div className="grid grid-cols-3 gap-1 w-full">
        {options.map((option) => (
          <button
            key={option.type}
            onClick={() => onChange(option.type)}
            className={`
              px-3 py-1.5 text-xs font-medium rounded-md transition-all duration-200
              flex items-center justify-center whitespace-nowrap w-full
              ${value === option.type
                ? 'bg-white text-emerald-600 shadow-sm'
                : 'text-content-light hover:bg-gray-200'
              }
            `}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};

// Fügen Sie diese neue Komponente hinzu
const ModelSwitch: React.FC<{
  models: LLMModel[];
  value: string;
  onChange: (value: string) => void;
}> = ({ models, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const selectedModel = models.find(model => model.id === value);

  return (
    <div ref={dropdownRef} className="relative w-full">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-gray-100 p-1.5 rounded-lg flex items-center justify-between text-xs font-medium hover:bg-gray-200 transition-colors duration-200"
        style={{ height: '34px' }}
      >
        <span className="flex items-center truncate">
          <Settings size={16} className="mr-2 flex-shrink-0" />
          <span className="truncate">{selectedModel?.name || 'Modell'}</span>
        </span>
        <svg
          className={`w-4 h-4 transition-transform duration-200 flex-shrink-0 ml-1 ${isOpen ? 'transform rotate-180' : ''}`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white rounded-lg shadow-lg border border-gray-200">
          {models.map((model) => (
            <button
              key={model.id}
              onClick={() => {
                onChange(model.id);
                setIsOpen(false);
              }}
              className={`
                w-full px-3 py-2 text-left text-xs hover:bg-gray-50 
                ${model.id === value ? 'text-emerald-600 bg-gray-50' : 'text-content-dark'}
                first:rounded-t-lg last:rounded-b-lg
              `}
              title={model.description}
            >
              <div className="font-medium truncate">{model.name}</div>
              <div className="text-xs text-content-light truncate">{model.description}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

// Fügen Sie diese neue Komponente hinzu
const TaleSkeleton: React.FC = () => {
  return (
    <motion.div
      initial={{ opacity: 0.5 }}
      animate={{ opacity: [0.5, 1, 0.5] }}
      transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
      className="flex items-center justify-between bg-white p-2 rounded-md shadow"
    >
      <div className="w-3/4 h-5 bg-gray-200 rounded" />
      <div className="w-6 h-6 bg-gray-200 rounded" />
    </motion.div>
  );
};

// Fügen Sie diese neue Komponente hinzu
const ImageSkeleton: React.FC = () => {
  return (
    <motion.div
      className="w-full pt-[100%] relative bg-gray-200 rounded-lg overflow-hidden"
      initial={{ opacity: 0.6 }}
      animate={{ opacity: [0.6, 1, 0.6] }}
      transition={{ repeat: Infinity, duration: 1.5, ease: "easeInOut" }}
    >
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-gray-200 via-gray-100 to-gray-200"
        animate={{ x: ['0%', '100%'] }}
        transition={{ repeat: Infinity, duration: 2, ease: "linear" }}
      />
    </motion.div>
  );
};

const TaleBear: React.FC<TaleBearProps> = ({ onLogout }) => {
  const [tales, setTales] = useState<Tale[]>([]);
  const [currentTale, setCurrentTale] = useState<Tale | null>(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [prompt, setPrompt] = useState('');
  const promptRef = useRef<HTMLTextAreaElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [isStoryGenerated, setIsStoryGenerated] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [showAudioControls, setShowAudioControls] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const talesPerPage = 5;
  const [targetAge, setTargetAge] = useState<string>('3-5');
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [isPromptBuilderOpen, setIsPromptBuilderOpen] = useState(false);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [userSubscription, setUserSubscription] = useState<UserSubscription | null>(null);
  const [userCredits, setUserCredits] = useState<number | null>(null);
  const [isSubscriptionModalOpen, setIsSubscriptionModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState(0);
  const menuRef = useRef<HTMLDivElement>(null);
  const [creditsPerRequest, setCreditsPerRequest] = useState<CreditsPerRequest>({ generate_story: 0, generate_audio: 0, generate_image: 0 });
  const [storiesLimit, setStoriesLimit] = useState<number>(0);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [storyLength, setStoryLength] = useState<string>('generate-story');
  const [storyLengthOptions, setStoryLengthOptions] = useState<StoryLength[]>([]);
  const [creativity, setCreativity] = useState<string>('balanced');
  const [creativityOptions] = useState<CreativityLevel[]>([
    { type: 'conservative', label: 'Konservativ', temperature: 0.3 },
    { type: 'balanced', label: 'Ausgewogen', temperature: 0.7 },
    { type: 'creative', label: 'Kreativ', temperature: 1.0 }
  ]);
  const [selectedModel, setSelectedModel] = useState<string>("llama-3.1-70b-versatile");
  const AVAILABLE_MODELS: LLMModel[] = [
    {
      id: "llama-3.1-70b-versatile",
      name: "Llama 3.1 70B",
      description: "Ausgewogenes Modell mit hoher Qualität",
      maxTokens: 4096
    },
    {
      id: "mixtral-8x7b-32768",
      name: "Mixtral 8x7B",
      description: "Schnelles Modell mit langer Kontextlänge",
      maxTokens: 32768
    }
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [signedImageUrl, setSignedImageUrl] = useState<string | null>(null);
  const [isGeneratingImage, setIsGeneratingImage] = useState(false);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const recognitionRef = useRef<any>(null);

  // Fügen Sie diese Hilfsfunktionen hinzu
  const hasEnoughCreditsForStory = useCallback(() => {
    const requiredCredits = storyLengthOptions.find(option => option.type === storyLength)?.credits || 0;
    return userCredits !== null && requiredCredits <= userCredits;
  }, [userCredits, storyLength, storyLengthOptions]);

  const hasEnoughCreditsForAudio = useCallback(() => {
    return userCredits !== null && creditsPerRequest.generate_audio <= userCredits;
  }, [userCredits, creditsPerRequest.generate_audio]);

  const showAlert = useCallback((message: string) => {
    setAlertMessage(message);
  }, []);

  const loadTales = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const loadedTales = await getTales(user.id);
        setTales(loadedTales);
      }
    } catch (error) {
      console.error('Fehler beim Laden der Geschichten:', error);
      showAlert('Fehler beim Laden der Geschichten');
    } finally {
      setIsLoading(false);
    }
  }, [showAlert]);

  const updateBadWordsList = useCallback(async () => {
    try {
      const words = await getBadWords();
      updateBadWords(words);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Content-Filters:', error);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      document.title = "talebear.com";
    }, 0);
    loadTales();
    updateBadWordsList();
    const intervalId = setInterval(() => {
      updateBadWordsList();
    }, 3600000); // Aktualisiere jede Stunde


    return () => clearInterval(intervalId);
  }, [loadTales, updateBadWordsList]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const filterAndUpdateCurrentTale = useCallback(async (tale: Tale) => {
    const filteredTitle = filterContent(tale.title);
    const filteredContent = filterContent(tale.content);

    if (filteredTitle !== tale.title || filteredContent !== tale.content) {
      try {
        const updatedTale = await updateFilteredTaleContent(tale.id, filteredTitle, filteredContent);
        setCurrentTale(updatedTale);
        setTales(prevTales => prevTales.map(t => t.id === updatedTale.id ? updatedTale : t));
      } catch (error) {
        console.error('Fehler beim Aktualisieren des gefilterten Inhalts:', error);
        showAlert('Fehler beim Aktualisieren des gefilterten Inhalts');
      }
    } else {
      setCurrentTale(tale);
    }
  }, [setTales, showAlert]);

  const setFilteredCurrentTale = useCallback((tale: Tale | null) => {
    if (tale) {
      filterAndUpdateCurrentTale(tale);
    } else {
      setCurrentTale(null);
    }
  }, [filterAndUpdateCurrentTale]);

  const createNewTale = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        if (tales.length >= storiesLimit) {
          showAlert('Du hast dein Geschichten-Limit erreicht. Bitte upgrade dein Abonnement für mehr Geschichten.');
          return;
        }

        const newTale = await saveTale({
          title: 'Neue Geschichte',
          content: '',
        });
        setTales([newTale, ...tales]);
        setFilteredCurrentTale(newTale);
        setIsStoryGenerated(false);
      }
    } catch (error) {
      console.error('Fehler beim Erstellen einer neuen Geschichte:', error);
      showAlert('Fehler beim Erstellen einer neuen Geschichte');
    }
  };

  const updateCurrentTale = useCallback(async (updater: (prevTale: Tale) => Tale) => {
    if (currentTale) {
      const updatedTale = updater(currentTale);
      setFilteredCurrentTale(updatedTale);
      setTales(prevTales => prevTales.map(tale =>
        tale.id === updatedTale.id ? updatedTale : tale
      ));
      try {
        await updateTale(updatedTale);
        // Fügen Sie hier einen console.log hinzu, um zu überprüfen, ob die Aktualisierung erfolgt
        console.log('Tale aktualisiert:', updatedTale);
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Geschichte:', error);
        showAlert('Fehler beim Aktualisieren der Geschichte');
      }
    }
  }, [currentTale, setFilteredCurrentTale, showAlert]);

  const handleDeleteTale = async (taleId: string) => {
    try {
      await deleteTale(taleId);
      setTales(tales.filter(tale => tale.id !== taleId));
      if (currentTale?.id === taleId) {
        setCurrentTale(null);
      }
    } catch (error) {
      console.error('Fehler beim Löschen der Geschichte:', error);
      showAlert('Fehler beim Löschen der Geschichte');
    }
  };

  const resetPromptField = useCallback(() => {
    console.log("resetPromptField wurde aufgerufen");
    setPrompt('');
    if (promptRef.current) {
      console.log("promptRef.current wurde gefunden");
      promptRef.current.style.height = 'auto';
    }
  }, []);

  const updateUserCreditsDisplay = useCallback(async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const credits = await getUserCredits(user.id);
        setUserCredits(credits);
      }
    } catch (error) {
      console.error('Fehler beim Aktualisieren der Benutzer-Credits:', error);
    }
  }, []);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const subscription = await getUserSubscription(user.id);
          setUserSubscription(subscription);

          let credits = await getUserCredits(user.id);

          credits = await getUserCredits(user.id);

          setUserCredits(credits);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
        showAlert('Fehler beim Laden der Benutzerdaten');
      }
    };

    loadUserData();
  }, [showAlert]);

  // Fügen Sie diesen useEffect-Hook hinzu, um die Credits pro Anfrage zu laden
  useEffect(() => {
    const loadCreditsPerRequest = async () => {
      try {
        const storyCredits = await getCreditsPerRequest('generate-story');
        const audioCredits = await getCreditsPerRequest('generate-audio');
        const imageCredits = await getCreditsPerRequest('generate-image');
        setCreditsPerRequest({ generate_story: storyCredits, generate_audio: audioCredits, generate_image: imageCredits });
      } catch (error) {
        console.error('Fehler beim Laden der Credits pro Anfrage:', error);
      }
    };

    loadCreditsPerRequest();
  }, []);

  useEffect(() => {
    const loadStoriesLimit = async () => {
      if (userSubscription) {
        const limit = await getStoriesLimit(userSubscription.tier);
        setStoriesLimit(limit);
      }
    };

    loadStoriesLimit();
  }, [userSubscription]);

  useEffect(() => {
    const loadStoryLengthOptions = async () => {
      try {
        const options = await getStoryLengthOptions();
        setStoryLengthOptions(options);
      } catch (error) {
        console.error('Fehler beim Laden der Geschichtenlängen-Optionen:', error);
      }
    };

    loadStoryLengthOptions();
  }, []);

  const generateStory = async () => {
    console.log("generateStory wurde aufgerufen");
    updateBadWordsList();
    setError(null);
    try {
      if (!currentTale || !prompt) {
        console.log("Kein aktuelles Tale oder Prompt", { currentTale, prompt });
        showAlert("Bitte wähle eine Geschichte aus und gebe einen Prompt ein.");
        return;
      }

      setIsGenerating(true);

      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Benutzer nicht authentifiziert');

      console.log("User ID:", user.id); // Fügen Sie diese Zeile hinzu, um die User ID zu überprüfen

      const selectedTemperature = creativityOptions.find(
        option => option.type === creativity
      )?.temperature || 0.7;

      const { data, error } = await supabase.functions.invoke('generate-story', {
        body: JSON.stringify({
          prompt,
          targetAge,
          userId: user.id,
          storyLength,
          temperature: selectedTemperature,
          modelId: selectedModel
        }),
      });

      if (error) {
        // Prüfen, ob es sich um einen 400 Fehler handelt
        if (error.message.includes('status code 400')) {
          const errorData = JSON.parse(error.message.split('Body:')[1]);
          if (errorData.error === 'Nicht genügend Credits') {
            showAlert(errorData.message);
            return;
          }
        }
        console.error("Fehler bei der Invoke-Funktion:", error);
        throw error;
      }

      console.log("Antwort erhalten:", data);

      if (data.error) {
        console.error('Generate story error:', data.error, data.details);
        throw new Error(`Generate story error: ${data.error}. Details: ${data.details}`);
      }

      if (data.choices && data.choices.length > 0) {
        const generatedContent = data.choices[0].message.content.trim();
        console.log("Generierter Inhalt:", generatedContent);

        // Generiere einen Titel für die Geschichte mit der neuen Funktion
        const titleResponse = await supabase.functions.invoke('generate-title', {
          body: JSON.stringify({
            storyContent: generatedContent,
            targetAge
          }),
        });

        if (titleResponse.error) throw titleResponse.error;

        const generatedTitle = titleResponse.data.choices[0].message.content.trim();

        console.log("Generierter Titel:", generatedTitle);

        await updateCurrentTale(prevTale => {
          const updatedTale = {
            ...prevTale,
            title: generatedTitle,
            content: generatedContent
          };
          console.log("Aktualisiertes Tale:", updatedTale);
          return updatedTale;
        });

        // Fügen Sie hier einen weiteren console.log hinzu
        console.log("Aktuelles Tale nach Aktualisierung:", currentTale);

        setIsStoryGenerated(true);
      } else {
        console.error("Unerwartetes Antwortformat:", data);
        throw new Error("Unerwartetes Antwortformat");
      }

      // Nach erfolgreicher Generierung, aktualisieren Sie die Credits-Anzeige
      await updateUserCreditsDisplay();

    } catch (error: unknown) {
      console.error("Detaillierter Fehler beim Generieren der Geschichte:", error);
      showAlert(`Fehler beim Generieren der Geschichte: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`);
    } finally {
      console.log("Setze isGenerating auf false");
      setIsGenerating(false);
      resetPromptField();
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !isGenerating && prompt) {
      generateStory();
    }
  };

  // Ändern Sie die handleGenerateNewAudio Funktion wie folgt:
  const handleGenerateNewAudio = async () => {
    if (!currentTale) {
      showAlert('Keine Geschichte zum Vorlesen ausgewählt');
      return;
    }

    if (!hasEnoughCreditsForAudio()) {
      handleInsufficientCredits();
      return;
    }

    setIsReading(true);
    setShowAudioControls(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Benutzer nicht authentifiziert');

      const disclaimer = "Diese Stimme wurde von einer künstlichen Intelligenz generiert.  ";
      const filteredTitle = filterContent(currentTale.title);
      const filteredContent = filterContent(currentTale.content);
      const fullText = disclaimer + filteredTitle + "\n\n" + filteredContent;

      const { data: { session } } = await supabase.auth.getSession();
      const accessToken = session?.access_token;

      const response = await fetch(
        `${process.env.REACT_APP_SUPABASE_URL}/functions/v1/generate-audio`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ prompt: fullText, userId: user.id }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Prüfe MediaSource Verfügbarkeit
      const isMediaSourceSupported = typeof MediaSource !== 'undefined' && MediaSource.isTypeSupported('audio/mpeg');

      if (!isMediaSourceSupported) {
        // Fallback für iOS und andere Plattformen ohne MediaSource Support
        console.log('Using fallback streaming method');
        const reader = response.body!.getReader();
        const chunks: Uint8Array[] = [];
        let audioUrl: string | null = null;

        const processStream = async () => {
          try {
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                console.log('Stream complete');
                break;
              }

              chunks.push(value);

              // Erstelle einen neuen Blob und URL nach jedem Chunk
              const blob = new Blob(chunks, { type: 'audio/mpeg' });
              const newUrl = URL.createObjectURL(blob);

              // Cleanup alte URL
              if (audioUrl) {
                URL.revokeObjectURL(audioUrl);
              }

              audioUrl = newUrl;
              setAudioUrl(newUrl);
            }
          } catch (error) {
            console.error('Error processing stream:', error);
            throw error;
          }
        };

        await processStream();
      } else {
        // Für andere Plattformen: Nutze MediaSource für Streaming
        const mediaSource = new MediaSource();
        const url = URL.createObjectURL(mediaSource);
        setAudioUrl(url);

        mediaSource.addEventListener('sourceopen', async () => {
          try {
            const sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
            const reader = response.body!.getReader();

            const bufferQueue: Uint8Array[] = [];
            let isProcessing = false;

            const processQueue = async () => {
              if (isProcessing || bufferQueue.length === 0 || sourceBuffer.updating) {
                return;
              }

              isProcessing = true;
              try {
                const chunk = bufferQueue.shift();
                if (chunk) {
                  sourceBuffer.appendBuffer(chunk);
                  await new Promise<void>((resolve) => {
                    sourceBuffer.addEventListener('updateend', () => resolve(), { once: true });
                  });
                }
              } finally {
                isProcessing = false;
                if (bufferQueue.length > 0) {
                  await processQueue();
                }
              }
            };

            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                while (bufferQueue.length > 0 || sourceBuffer.updating) {
                  await new Promise(resolve => setTimeout(resolve, 10));
                }
                mediaSource.endOfStream();
                break;
              }

              bufferQueue.push(value);

              if (!isProcessing) {
                await processQueue();
              }
            }
          } catch (error) {
            console.error('Error processing audio stream:', error);
            if (!mediaSource.readyState.includes('closed')) {
              mediaSource.endOfStream();
            }
          }
        });
      }

      await updateUserCreditsDisplay();

    } catch (error) {
      console.error('Fehler beim Generieren und Abspielen der Audiodatei:', error);
      showAlert(`Fehler beim Generieren und Abspielen der Audiodatei: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`);
      setShowAudioControls(false);
    } finally {
      setIsReading(false);
    }
  };

  const handlePlayExistingAudio = async () => {
    if (!currentTale) {
      showAlert('Keine Geschichte zum Vorlesen ausgewählt');
      return;
    }

    setIsReading(true);

    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('Benutzer nicht authentifiziert');
      }

      let audioUrl = await getAudioUrl(user.id, currentTale.id);
      if (!audioUrl) {
        throw new Error('Existierende Audio-URL konnte nicht abgerufen werden');
      }

      // Passe die URL für die lokale Entwicklung an
      if (process.env.REACT_APP_SUPABASE_URL?.includes('localhost')) {
        audioUrl = audioUrl.replace(/^https?:\/\/[^/]+/, 'http://localhost:54321');
      }

      console.log('Wiedergabe vorhandenes Audio');
      setAudioUrl(audioUrl);
      setShowAudioControls(true);
    } catch (error) {
      console.error('Fehler beim Abspielen der existierenden Audiodatei:', error);
      showAlert('Es gab einen Fehler beim Vorlesen der Geschichte. Bitte versuchen Sie es später erneut.');
    } finally {
      setIsReading(false);
    }
  };

  const handleCloseAudioControls = () => {
    setShowAudioControls(false);
    setAudioUrl(null);
  };

  const handleDownload = async () => {
    try {
      if (!currentTale?.audio_url) {
        throw new Error('Keine Audio-URL verfügbar');
      }


      // Bereinige den Titel für den Dateinamen
      const sanitizedTitle = currentTale.title
        .replace(/[^\w\s-]/g, '') // Entferne alle Sonderzeichen außer Bindestriche und Leerzeichen
        .trim()
        .replace(/\s+/g, '_') // Ersetze Leerzeichen durch Unterstriche
        .toLowerCase();

      // Füge Datum hinzu für Eindeutigkeit
      const date = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
      const fileName = `${sanitizedTitle}_${date}.mp3`;

      // Hole die signierte URL vom Storage
      const { data: signedUrlData } = await supabase.storage
        .from('tale_audio')
        .createSignedUrl(currentTale.audio_url, 60);

      if (!signedUrlData?.signedUrl) {
        throw new Error('Konnte keine signierte URL erstellen');
      }

      // Lade die Audiodatei
      const response = await fetch(signedUrlData.signedUrl);
      if (!response.ok) throw new Error('Download fehlgeschlagen');

      // Hole den Blob
      const blob = await response.blob();

      // Erstelle einen Download-Link
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Fehler beim Download:', error);
      toast.error('Fehler beim Download der Audiodatei');
    } finally {
    }
  };

  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: 'created_at',
    direction: 'desc'
  });

  const sortTales = useCallback((talesArray: Tale[]) => {
    return [...talesArray].sort((a, b) => {
      if (sortConfig.key === 'title') {
        const comparison = a.title.localeCompare(b.title);
        return sortConfig.direction === 'asc' ? comparison : -comparison;
      } else {
        const dateA = new Date(a.created_at).getTime();
        const dateB = new Date(b.created_at).getTime();
        return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
      }
    });
  }, [sortConfig]);

  const [searchTerm, setSearchTerm] = useState('');

  const filterTales = useCallback((talesArray: Tale[]) => {
    if (!searchTerm) return talesArray;

    return talesArray.filter(tale =>
      tale.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [searchTerm]);

  // Fügen Sie diese useEffect hinzu, um die currentPage zurückzusetzen, wenn sich searchTerm oder sortConfig ändert
  useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm, sortConfig]);

  // Aktualisieren Sie die getFilteredAndSortedTales Funktion
  const getFilteredAndSortedTales = useCallback(() => {
    // Erst filtern
    const filteredTales = filterTales(tales);
    // Dann sortieren
    return sortTales(filteredTales);
  }, [tales, filterTales, sortTales]); // Überflüssige Abhängigkeiten entfernt

  // Ersetzen Sie die bestehende Berechnung durch:
  const sortedAndFilteredTales = getFilteredAndSortedTales();
  const totalPages = Math.ceil(sortedAndFilteredTales.length / talesPerPage);
  const indexOfLastTale = currentPage * talesPerPage;
  const indexOfFirstTale = indexOfLastTale - talesPerPage;
  const currentTales = sortedAndFilteredTales.slice(indexOfFirstTale, indexOfLastTale);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  // Fügen Sie diese neue Funktion hinzu:
  const adjustInputHeight = useCallback(() => {
    if (promptRef.current) {
      const textarea = promptRef.current;
      const select = document.querySelector('select') as HTMLSelectElement;
      const button = document.querySelector('button[onClick="generateStory"]') as HTMLButtonElement;

      textarea.style.height = 'auto';
      const newHeight = Math.max(textarea.scrollHeight, isMobile ? 64 : 38);
      textarea.style.height = `${newHeight}px`;

      if (select) select.style.height = `${newHeight}px`;
      if (button) button.style.height = `${newHeight}px`;
    }
  }, [isMobile]);



  // Fügen Sie diesen useEffect-Hook hinzu:
  useEffect(() => {
    window.addEventListener('resize', adjustInputHeight);
    return () => {
      window.removeEventListener('resize', adjustInputHeight);
    };
  }, [adjustInputHeight]);

  // Ändern Sie den useEffect-Hook, der auf nderungen des Prompts reagiert:
  useEffect(() => {
    adjustInputHeight();
  }, [prompt, adjustInputHeight]);

  const handlePromptChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPrompt(e.target.value);
  };

  const handlePromptGenerated = (generatedPrompt: string) => {
    setPrompt(generatedPrompt);
    setIsPromptBuilderOpen(false);
  };

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          const subscription = await getUserSubscription(user.id);
          setUserSubscription(subscription);

          let credits = await getUserCredits(user.id);

          // Wenn keine Credits vorhanden sind oder es Zeit für eine Auffüllung ist, füllen wir sie auf
          if (credits === 0 || await shouldRefillCredits(user.id)) {
            await refillCredits(user.id);
            credits = await getUserCredits(user.id);
          }

          setUserCredits(credits);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
        showAlert('Fehler beim Laden der Benutzerdaten');
      }
    };

    loadUserData();
  }, [showAlert]);

  useEffect(() => {
    if (menuRef.current) {
      setMenuHeight(isMenuOpen ? menuRef.current.scrollHeight : 0);
    }
  }, [isMenuOpen]);

  // Fügen Sie diese Funktion am Anfang der Komponente hinzu:
  const getPageRange = useCallback((currentPage: number, totalPages: number) => {
    if (totalPages <= 5) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    if (currentPage <= 3) {
      return [1, 2, 3, 4, '...', totalPages];
    }

    if (currentPage >= totalPages - 2) {
      return [1, '...', totalPages - 3, totalPages - 2, totalPages - 1, totalPages];
    }

    return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
  }, []);

  const handleInsufficientCredits = () => {
    setIsSubscriptionModalOpen(true);
  };

  // Fügen Sie diese neue Funktion hinzu
  const handleCreateNewTale = () => {
    if (tales.length >= storiesLimit) {
      setIsSubscriptionModalOpen(true);
    } else {
      createNewTale();
    }
  };

  // Fügen Sie am Anfang der Komponente hinzu:

  useEffect(() => {
    // Prüfe URL-Parameter beim Laden der Seite
    const urlParams = new URLSearchParams(window.location.search);
    const showSubscriptionModal = urlParams.get('showSubscriptionModal');
    const success = urlParams.get('success');
    const canceled = urlParams.get('canceled');

    // Entferne die Parameter aus der URL ohne Neuladen der Seite
    window.history.replaceState({}, '', window.location.pathname);

    // Zeige das SubscriptionModal bei success oder showSubscriptionModal
    if (success === 'true') {
      setIsSubscriptionModalOpen(true);
      showAlert('Ihr Abonnement wurde erfolgreich aktiviert! Die Änderungen werden in wenigen Minuten wirksam.');
    } else if (canceled === 'true') {
      setIsSubscriptionModalOpen(true);
      showAlert('Der Zahlungsvorgang wurde abgebrochen.');
    } else if (showSubscriptionModal === 'true') {
      setIsSubscriptionModalOpen(true);
    }
  }, [showAlert]);

  // Fügen Sie diese Hilfsfunktion hinzu
  const getRequiredCredits = useCallback(() => {
    return storyLengthOptions.find(option => option.type === storyLength)?.credits || 0;
  }, [storyLength, storyLengthOptions]);

  // Im TaleBear-Komponenten, fügen Sie diese Funktion hinzu (vor dem return statement):
  const handleSort = (key: SortConfig['key']) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  useEffect(() => {
    if (!currentTale) return;

    // Channel für Tale Updates einrichten
    const channel = supabase
      .channel(`tale_updates_${currentTale.id}`)
      .on(
        'postgres_changes',
        {
          event: 'UPDATE',
          schema: 'public',
          table: 'tales',
          filter: `id=eq.${currentTale.id}`,
        },
        (payload: any) => {
          console.log('Tale updated:', payload);
          setCurrentTale(payload.new as Tale);
        }
      )
      .subscribe();

    // Cleanup subscription
    return () => {
      channel.unsubscribe();
    };
  }, [currentTale]);

  // Füge useEffect für Realtime Credits Updates hinzu
  useEffect(() => {
    const setupRealtimeCredits = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (!user) return;

        // Channel für Credits Updates einrichten
        const channel = supabase
          .channel('credits_updates')
          .on(
            'postgres_changes',
            {
              event: '*', // Lausche auf INSERT, UPDATE, DELETE
              schema: 'public',
              table: 'user_credits',
              filter: `user_id=eq.${user.id}`,
            },
            (payload) => {
              console.log('Credits updated:', payload);
              // Aktualisiere die Credits im State
              if (payload.eventType === 'DELETE') {
                setUserCredits(0);
              } else {
                setUserCredits(payload.new.credits);
              }
            }
          )
          .subscribe();

        // Cleanup subscription
        return () => {
          channel.unsubscribe();
        };
      } catch (error) {
        console.error('Fehler beim Einrichten der Realtime Credits:', error);
      }
    };

    setupRealtimeCredits();
  }, []); // Leere Dependency Array, da wir nur beim Mount subscriben wollen

  // Neue Hilfsfunktion zum ��berprüfen des Hashes
  const hasContentChanged = useCallback(() => {
    if (!currentTale || !currentTale.audio_url) return true;
    
    const currentHash = calculateContentHash(currentTale.title, currentTale.content);
    return currentHash !== currentTale.content_hash;
  }, [currentTale]);

  const generateImage = async () => {
    if (!currentTale) {
      showAlert('Keine Geschichte zum Illustrieren ausgewählt');
      return;
    }

    setIsGeneratingImage(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Benutzer nicht authentifiziert');

      const { data, error } = await supabase.functions.invoke('generate-image', {
        body: JSON.stringify({
          title: currentTale.title,
          content: currentTale.content,
          userId: user.id
        }),
      });

      if (error) {
        if (error.message.includes('status code 400')) {
          const errorData = JSON.parse(error.message.split('Body:')[1]);
          if (errorData.error === 'Nicht genügend Credits') {
            showAlert(errorData.message);
            return;
          }
        }
        throw error;
      }

      await updateCurrentTale(prevTale => ({
        ...prevTale,
        image_url: data.image_url
      }));

      await updateUserCreditsDisplay();

    } catch (error) {
      console.error('Fehler bei der Bildgenerierung:', error);
      showAlert(`Fehler bei der Bildgenerierung: ${error instanceof Error ? error.message : 'Unbekannter Fehler'}`);
    } finally {
      setIsGeneratingImage(false);
    }
  };

  const hasEnoughCreditsForImage = () => {
    return userCredits !== null && userCredits >= creditsPerRequest.generate_image;
  };

  // Fügen Sie diese Funktion hinzu
  const getImageUrl = async (imagePath: string) => {
    const { data, error } = await supabase.storage
      .from('tale_images')
      .createSignedUrl(imagePath, 3600); // URL ist 1 Stunde gültig

    if (error) {
      console.error('Fehler beim Erstellen der signierten URL:', error);
      return null;
    }

    return data.signedUrl;
  };

  useEffect(() => {
    const loadSignedUrl = async () => {
      if (currentTale?.image_url) {
        const url = await getImageUrl(currentTale.image_url);
        setSignedImageUrl(url);
      } else {
        setSignedImageUrl(null);
      }
    };

    loadSignedUrl();
  }, [currentTale?.image_url]); // Aktualisiert die URL wenn sich image_url ändert

  // Neue Funktion für Speech-to-Text
  const startListening = useCallback(() => {
    try {
      const SpeechRecognition = (window as unknown as IWindow).webkitSpeechRecognition;
      if (!SpeechRecognition) {
        showAlert('Spracherkennung wird in diesem Browser nicht unterstützt');
        return;
      }

      if (!recognitionRef.current) {
        recognitionRef.current = new SpeechRecognition();
        recognitionRef.current.continuous = false;
        recognitionRef.current.interimResults = false;
        recognitionRef.current.lang = 'de-DE';

        recognitionRef.current.onresult = (event: any) => {
          const transcript = event.results[0][0].transcript;
          setPrompt(prev => prev + (prev ? ' ' : '') + transcript);
          setIsListening(false);
        };

        recognitionRef.current.onerror = (event: any) => {
          console.error('Spracherkennungsfehler:', event.error);
          setIsListening(false);
          showAlert('Fehler bei der Spracherkennung: ' + event.error);
        };

        recognitionRef.current.onend = () => {
          setIsListening(false);
        };
      }

      recognitionRef.current.start();
      setIsListening(true);
    } catch (error) {
      console.error('Fehler beim Starten der Spracherkennung:', error);
      showAlert('Fehler beim Starten der Spracherkennung');
      setIsListening(false);
    }
  }, [showAlert]);

  // Cleanup für Speech Recognition
  useEffect(() => {
    return () => {
      if (recognitionRef.current) {
        recognitionRef.current.stop();
      }
    };
  }, []);

  return (
    <Background showAudioControls={false}>
      <a
        href="#main-content"
        className="sr-only focus:not-sr-only focus:absolute focus:z-50 focus:p-4 focus:bg-white focus:text-primary-600"
      >
        Zum Hauptinhalt springen
      </a>
      <div className="flex flex-col min-h-screen">
        <div className="flex-grow">
          <div className="max-w-sm md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
            <nav className="bg-white rounded-lg shadow-lg p-2 mb-4">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-primary-600 font-heading">talebear.com</h1>
                
                {/* Flex-Container für mobile Ansicht mit justify-end */}
                <div className="md:hidden flex items-center justify-end flex-grow ml-4">
                  {userSubscription ? (
                    <div className="text-sm text-content-light mr-2">
                      <span className="font-semibold">{userSubscription.tier.charAt(0).toUpperCase() + userSubscription.tier.slice(1)}</span>
                    </div>
                  ) : (
                    <div className="text-sm text-content-light mr-2">
                      <span className="font-semibold">Kein Abo</span>
                    </div>
                  )}
                  {userCredits !== null && (
                    <div className="flex items-center text-sm text-content-light mr-2">
                      <Coins size={16} className="mr-1" />
                      <span>{userCredits}</span>
                    </div>
                  )}
                  <button
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                    className="text-content hover:text-content-dark"
                  >
                    <Menu size={24} />
                  </button>
                </div>

                {/* Desktop Menü bleibt unverändert */}
                <div className="hidden md:flex items-center">
                  {userSubscription ? (
                    <div className="text-sm text-content-light mr-2">
                      <span className="font-semibold">{userSubscription.tier.charAt(0).toUpperCase() + userSubscription.tier.slice(1)}</span>
                    </div>
                  ) : (
                    <div className="text-sm text-content-light mr-2">
                      <span className="font-semibold">Kein Abo</span>
                    </div>
                  )}
                  {userCredits !== null && (
                    <div className="flex items-center text-sm text-content-light mr-2">
                      <Coins size={16} className="mr-1" />
                      <span>{userCredits}</span>
                    </div>
                  )}
                  <button
                    onClick={() => setShowTransactionHistory(true)}
                    className="bg-secondary-500 text-white p-2 rounded-lg hover:bg-secondary-600 transition-colors duration-200 flex items-center mr-2"
                    title="Credit-Transaktionen anzeigen"
                  >
                    <History size={20} />
                  </button>
                  <button
                    onClick={() => setIsFeedbackModalOpen(true)}
                    className="bg-secondary-500 text-white p-2 rounded-lg hover:bg-secondary-600 transition-colors duration-200 flex items-center mr-2"
                    title="Feedback geben"
                  >
                    <MessageSquare size={20} />
                  </button>
                  <button
                    onClick={() => setIsSubscriptionModalOpen(true)}
                    className="bg-primary-500 text-white p-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 flex items-center mr-2"
                    title="Abonnement verwalten"
                  >
                    <CreditCard size={20} />
                  </button>
                  <button
                    onClick={() => setIsProfileModalOpen(true)}
                    className="bg-primary-500 text-white p-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 flex items-center mr-2"
                    title="Einstellungen"
                  >
                    <Settings size={20} />
                  </button>
                  <button
                    onClick={onLogout}
                    className="bg-red-500 text-white p-2 rounded-lg hover:bg-red-600 transition-colors duration-200"
                    title="Abmelden"
                  >
                    <LogOutIcon size={20} />
                  </button>
                </div>
              </div>
              <div
                ref={menuRef}
                className="overflow-hidden transition-[max-height] duration-300 ease-in-out"
                style={{ maxHeight: `${menuHeight}px` }}
              >
                <div className="mt-4 md:hidden">
                  <div className="flex flex-col space-y-2">
                    <button
                      onClick={() => setShowTransactionHistory(true)}
                      className="bg-secondary-500 text-white px-4 py-2 rounded-lg hover:bg-secondary-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <History size={20} className="mr-2" />
                      Credit-Transaktionen
                    </button>
                    <button
                      onClick={() => setIsFeedbackModalOpen(true)}
                      className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-purple-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <MessageSquare size={20} className="mr-2" />
                      Feedback geben
                    </button>
                    <button
                      onClick={() => setIsSubscriptionModalOpen(true)}
                      className="bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <CreditCard size={20} className="mr-2" />
                      Abonnement verwalten
                    </button>
                    <button
                      onClick={() => setIsProfileModalOpen(true)}
                      className="bg-primary-500 text-white px-4 py-2 rounded-lg hover:bg-primary-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <Settings size={20} className="mr-2" />
                      Einstellungen
                    </button>
                    <button
                      onClick={onLogout}
                      className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors duration-200 flex items-center justify-center"
                    >
                      <LogOutIcon size={20} className="mr-2" />
                      Abmelden
                    </button>
                  </div>
                </div>
              </div>
            </nav>
          </div>

          {/* Ladeindikator */}
          {isGenerating && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-4 rounded-lg shadow-lg flex items-center">
                <Loader className="animate-spin text-emerald-500 mr-2" size={24} />
                <span className="text-content-dark font-semibold">Geschichte wird generiert...</span>
              </div>
            </div>
          )}

          <div className="bg-white rounded-lg shadow-lg p-4 max-w-sm md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">

            {/* Hauptinhalt der App */}
            <div className="flex flex-col items-center">
              <div className="w-full max-w-md flex flex-col items-center mb-4">
                <button
                  className={`bg-primary-500 text-white p-2 rounded-lg flex flex-col items-center justify-center text-sm font-semibold hover:bg-primary-600 transition-colors duration-200 w-full mb-4 font-heading relative ${tales.length >= storiesLimit ? 'bg-yellow-500 hover:bg-yellow-600' : ''
                    }`}
                  onClick={handleCreateNewTale}
                >
                  <BookOpen className="mb-1" size={20} />
                  {tales.length >= storiesLimit ? 'Upgrade für mehr Geschichten' : 'Neue Geschichte erfinden'}
                  <span className={`absolute -top-2 -right-2 ${tales.length >= storiesLimit ? 'bg-red-500' : 'bg-blue-500'
                    } text-white text-xs rounded-full px-2 py-1`}>
                    {tales.length}/{storiesLimit}
                  </span>
                </button>

                {tales.length > 0 && (
                  <div className="bg-gray-100 p-3 rounded-lg w-full">
                    <h2 className="text-lg font-bold mb-2 text-content-dark font-heading">Deine Geschichten</h2>

                    {/* Suchfeld und Sortierungsbuttons */}
                    <div className="flex justify-between items-center mb-2 gap-2">
                      {/* Suchfeld mit Reset-Button */}
                      <div className="relative flex-grow max-w-md">
                        <input
                          type="text"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          placeholder={isMobile ? "Suchen..." : "Geschichten durchsuchen..."}
                          className="w-full px-3 py-1.5 pr-8 text-sm rounded-lg border border-gray-200 focus:border-primary-500 focus:ring-2 focus:ring-primary-100 transition-colors duration-200"
                          style={{ height: '34px' }}
                        />
                        {searchTerm && (
                          <button
                            onClick={() => setSearchTerm('')}
                            className="absolute right-2 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 transition-colors"
                            title="Suche zurücksetzen"
                          >
                            <X size={16} />
                          </button>
                        )}
                      </div>

                      {/* Sortierungsbuttons */}
                      <div className="flex space-x-2">
                        <button
                          onClick={() => handleSort('title')}
                          className={`flex items-center px-3 rounded-lg text-xs ${sortConfig.key === 'title'
                            ? 'bg-primary-500 text-white'
                            : 'bg-gray-200 text-content hover:bg-gray-300'
                            }`}
                          style={{ height: '34px' }}
                          title="Nach Titel sortieren"
                        >
                          {sortConfig.key === 'title' && sortConfig.direction === 'desc' ? (
                            <ArrowUpZA size={14} className="mr-1" />
                          ) : (
                            <ArrowDownAZ size={14} className="mr-1" />
                          )}
                        </button>
                        <button
                          onClick={() => handleSort('created_at')}
                          className={`flex items-center px-3 rounded-lg text-xs ${sortConfig.key === 'created_at'
                            ? 'bg-primary-500 text-white'
                            : 'bg-gray-200 text-content hover:bg-gray-300'
                            }`}
                          style={{ height: '34px' }}
                          title="Nach Datum sortieren"
                        >
                          {sortConfig.key === 'created_at' && sortConfig.direction === 'desc' ? (
                            <CalendarArrowDown size={14} className="mr-1" />
                          ) : (
                            <CalendarArrowUp size={14} className="mr-1" />
                          )}
                        </button>
                      </div>
                    </div>

                    <ul className="space-y-2 mb-4">
                      <AnimatePresence mode="popLayout">
                        {isLoading ? (
                          // Skeleton Loading während des Ladens
                          Array.from({ length: 5 }).map((_, index) => (
                            <motion.li
                              key={`skeleton-${index}`}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.2, delay: index * 0.05 }}
                            >
                              <TaleSkeleton />
                            </motion.li>
                          ))
                        ) : (
                          // Echte Tales nach dem Laden
                          currentTales.map((tale) => (
                            <motion.li
                              key={tale.id}
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, scale: 0 }}
                              transition={{ duration: 0.2 }}
                              layout
                              className={`flex items-center justify-between bg-white p-2 rounded-md shadow text-sm cursor-pointer hover:bg-gray-50 transition-colors ${
                                tale.id === currentTale?.id ? 'border-2 border-primary-500' : ''
                              }`}
                              onClick={() => setFilteredCurrentTale(tale)}
                            >
                              <span className="font-medium text-content">{tale.title}</span>
                              <div className="flex items-center">
                                {tale.id === currentTale?.id && tale.content === '' && (
                                  <motion.div
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1 }}
                                    transition={{ type: "spring", stiffness: 500, damping: 30 }}
                                  >
                                    <Award className="text-yellow-500 mr-2" size={16} />
                                  </motion.div>
                                )}
                                <motion.button
                                  whileHover={{ scale: 1.1 }}
                                  whileTap={{ scale: 0.9 }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteTale(tale.id);
                                  }}
                                  className="text-red-500 hover:text-red-700"
                                  aria-label={`Geschichte "${tale.title}" löschen`}
                                  title={`Geschichte "${tale.title}" löschen`}
                                >
                                  <Trash2 size={16} />
                                  <span className="sr-only">Geschichte "{tale.title}" löschen</span>
                                </motion.button>
                              </div>
                            </motion.li>
                          ))
                        )}
                      </AnimatePresence>
                    </ul>
                    {totalPages > 1 && (
                      <div className="flex justify-center items-center space-x-1 mt-4 text-sm">
                        <button
                          onClick={() => paginate(Math.max(1, currentPage - 1))}
                          disabled={currentPage === 1}
                          className="px-1.5 py-0.5 rounded bg-gray-200 text-content-dark hover:bg-gray-300 disabled:opacity-50"
                        >
                          &lt;
                        </button>
                        {getPageRange(currentPage, totalPages).map((page, index) => (
                          <React.Fragment key={index}>
                            {typeof page === 'number' ? (
                              <button
                                onClick={() => paginate(page)}
                                className={`px-2 py-0.5 rounded ${currentPage === page
                                  ? 'bg-primary-500 text-white'
                                  : 'bg-gray-200 text-content hover:bg-gray-300'
                                  }`}
                              >
                                {page}
                              </button>
                            ) : (
                              <span className="px-1 py-0.5">...</span>
                            )}
                          </React.Fragment>
                        ))}
                        <button
                          onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
                          disabled={currentPage === totalPages}
                          className="px-1.5 py-0.5 rounded bg-gray-200 text-content-dark hover:bg-gray-300 disabled:opacity-50"
                        >
                          &gt;
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="w-full md:w-3/4">
                {currentTale && (
                  <div>
                    <input
                      type="text"
                      id="story-title"
                      className="w-full p-2 border rounded mb-2 font-bold text-content"
                      value={currentTale.title}
                      onChange={(e) => updateCurrentTale(prevTale => ({ ...prevTale, title: e.target.value }))}
                      placeholder="Titel der Geschichte"
                    />
                    <textarea
                      id="story-content"
                      className={`w-full h-64 md:h-[calc(100vh-300px)] p-2 border rounded mb-2 text-content ${!currentTale.content && 'bg-gray-100'}`}
                      value={currentTale.content}
                      onChange={(e) => updateCurrentTale(prevTale => ({ ...prevTale, content: e.target.value }))}
                      placeholder="Hier erscheint bald die Geschichte..."
                      disabled={isGenerating || (!currentTale.content && !isStoryGenerated)}
                    />

                    {/* Fügen Sie hier eine neue div mit zusätzlichem Abstand hinzu */}
                    <div className="mb-6">
                      {/* Story Settings über dem Promptfeld */}
                      <div className="flex flex-col lg:flex-row justify-center gap-4 mb-4 w-full">
                        <div className="flex flex-col md:flex-row lg:w-2/3 gap-4">
                          <div className="w-full md:w-1/2">
                            <StoryLengthSwitch
                              options={storyLengthOptions}
                              value={storyLength}
                              onChange={setStoryLength}
                            />
                          </div>
                          <div className="w-full md:w-1/2">
                            <CreativitySwitch
                              options={creativityOptions}
                              value={creativity}
                              onChange={setCreativity}
                            />
                          </div>
                        </div>
                        <div className="w-full lg:w-1/3">
                          <ModelSwitch
                            models={AVAILABLE_MODELS}
                            value={selectedModel}
                            onChange={setSelectedModel}
                          />
                        </div>
                      </div>

                      {/* Prompt-Bereich */}
                      <div className="flex mb-2">
                        <label htmlFor="target-age" className="sr-only">
                          Zielgruppe: Alter des Kindes
                        </label>
                        <select
                          id="target-age"
                          name="target-age"
                          aria-label="Wähle das Alter des Kindes"
                          className="p-2 border rounded-l text-sm flex-shrink-0"
                          value={targetAge}
                          onChange={(e) => setTargetAge(e.target.value)}
                        >
                          <option value="" disabled>Alter</option>
                          <option value="6-12 Monaten">6-12 Mon.</option>
                          <option value="1-2 Jahren">1-2 J.</option>
                          <option value="3-5 Jahren">3-5 J.</option>
                          <option value="6-8 Jahren">6-8 J.</option>
                          <option value="9-12 Jahren">9-12 J.</option>
                        </select>

                        <div className="
                          relative flex-grow
                          grid
                          text-sm
                          after:px-3.5
                          after:py-2.5
                          [&>textarea]:text-inherit
                          after:text-inherit
                          [&>textarea]:resize-none
                          [&>textarea]:overflow-hidden
                          [&>textarea]:[grid-area:1/1/2/2]
                          after:[grid-area:1/1/2/2]
                          after:whitespace-pre-wrap
                          after:invisible
                          after:content-[attr(data-cloned-val)_'_']
                          after:border
                        ">
                          <textarea
                            ref={promptRef}
                            className="w-full text-slate-600 bg-slate-100 border border-transparent hover:border-secondary-400 appearance-none px-3.5 py-2.5 outline-none focus:bg-white focus:border-secondary-400 focus:ring-2 focus:ring-secondary-100 text-sm"
                            value={prompt}
                            onChange={handlePromptChange}
                            onKeyUp={(e) => {
                              if (e.key === 'Enter' && !e.shiftKey) {
                                e.preventDefault();
                                handleKeyPress(e as any);
                              }
                            }}
                            placeholder={isMobile ? "Geschichte beschreiben..." : "Gib eine Beschreibung deiner Geschichte ein..."}
                            disabled={isGenerating || isListening}
                            rows={isMobile ? 3 : 2}
                          />
                        </div>

                        {/* Neuer Mikrofon-Button */}
                        <button
                          className={`bg-secondary-500 text-white p-2 flex items-center justify-center transition-colors duration-200 relative flex-shrink-0
                            ${isListening ? 'animate-pulse bg-red-500' : 'hover:bg-secondary-600'}`}
                          onClick={startListening}
                          disabled={isGenerating}
                          title={isListening ? 'Aufnahme stoppen' : 'Spracheingabe starten'}
                        >
                          {isListening ? <MicOff size={24} className="animate-pulse" /> : <Mic size={24} />}
                        </button>

                        <button
                          className="bg-primary-500 text-white p-2 rounded-r flex items-center justify-center hover:bg-primary-600 transition-colors duration-200 relative flex-shrink-0"
                          onClick={hasEnoughCreditsForStory() ? generateStory : handleInsufficientCredits}
                        >
                          <Send size={24} />
                          {userCredits !== null && (
                            <span className={`absolute -top-3 -right-3 ${hasEnoughCreditsForStory() ? 'bg-primary-500' : 'bg-red-500'} text-white text-xs rounded-full px-2 py-1 flex items-center`}>
                              {getRequiredCredits()}/{userCredits} <Coins size={12} className="ml-1" />
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                    {/* Fügen Sie den PromptBuilder hier ein */}
                    <PromptBuilder
                      onPromptGenerated={handlePromptGenerated}
                      isOpen={isPromptBuilderOpen}
                      onToggle={() => setIsPromptBuilderOpen(!isPromptBuilderOpen)}
                    />

                    {currentTale.content && (
                      <div className="space-y-4">
                        {/* Bildanzeige mit Skeleton */}
                        <AnimatePresence>
                          {(isGeneratingImage || currentTale.image_url) && (
                            <motion.div 
                              className="bg-white rounded-lg shadow-lg p-4"
                              initial={{ opacity: 0, y: 20 }}
                              animate={{ opacity: 1, y: 0 }}
                              exit={{ opacity: 0, y: -20 }}
                              transition={{ duration: 0.3, ease: "easeOut" }}
                            >
                              <AnimatePresence mode="wait">
                                {isGeneratingImage ? (
                                  <motion.div
                                    key="skeleton"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                  >
                                    <ImageSkeleton />
                                  </motion.div>
                                ) : (
                                  currentTale.image_url && signedImageUrl && (
                                    <motion.img 
                                      key="image"
                                      src={signedImageUrl}
                                      alt={`Illustration für ${currentTale.title}`}
                                      className="w-full aspect-square object-cover rounded-lg"
                                      loading="lazy"
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                      transition={{ duration: 0.3 }}
                                    />
                                  )
                                )}
                              </AnimatePresence>
                            </motion.div>
                          )}
                        </AnimatePresence>
                        
                        {/* Bestehende Buttons für Audio und Bildgenerierung */}
                        <div className="grid grid-cols-2 gap-2 mb-4 w-full">
                          <button
                            className={`${currentTale.audio_url ? 'col-span-1' : 'col-span-1'} 
                              bg-secondary-400 p-2 rounded-lg flex flex-col items-center justify-center 
                              text-xs font-medium transition-colors duration-200 relative
                              ${hasContentChanged() ? 'hover:bg-secondary-500' : 'opacity-50 cursor-not-allowed'}`}
                            onClick={handleGenerateNewAudio}
                            disabled={!currentTale || 
                                      currentTale.content.trim() === '' || 
                                      isReading || 
                                      audioUrl !== null || 
                                      !hasContentChanged()}
                          >
                            {isReading ? (
                              <Loader className="animate-spin mb-1" size={20} />
                            ) : audioUrl !== null ? (
                              <Volume2 className="mb-1" size={20} />
                            ) : (
                              <Volume2 className="mb-1" size={20} />
                            )}
                            {isReading ? 'Wird vorbereitet...' : 
                             audioUrl !== null ? 'Wird vorgelesen...' : 
                             currentTale.audio_url && !hasContentChanged() ? 'Keine Änderungen' : 'Vorlesen'}
                            {userCredits !== null && (
                              <span className={`absolute -top-2 -right-2 
                                ${hasEnoughCreditsForAudio() ? 'bg-primary-500' : 'bg-red-500'} 
                                text-white text-xs rounded-full px-2 py-1 flex items-center`}>
                                {creditsPerRequest.generate_audio}/{userCredits} <Coins size={12} className="ml-1" />
                              </span>
                            )}
                          </button>

                          {/* Bildgenerierungs-Button */}
                          <button
                            className="col-span-1 bg-primary-400 p-2 rounded-lg flex flex-col items-center justify-center 
                              text-xs font-medium hover:bg-primary-500 transition-colors duration-200 relative"
                            onClick={generateImage}
                            disabled={isGeneratingImage || !currentTale || currentTale.content.trim() === ''}
                          >
                            {isGeneratingImage ? (
                              <Loader className="animate-spin mb-1" size={20} />
                            ) : (
                              <Image className="mb-1" size={20} />
                            )}
                            {isGeneratingImage ? 'Wird generiert...' : 
                             currentTale?.image_url ? 'Bild neu generieren' : 'Bild generieren'}
                            {userCredits !== null && (
                              <span className={`absolute -top-2 -right-2 
                                ${hasEnoughCreditsForImage() ? 'bg-primary-500' : 'bg-red-500'} 
                                text-white text-xs rounded-full px-2 py-1 flex items-center`}>
                                {creditsPerRequest.generate_image}/{userCredits} <Coins size={12} className="ml-1" />
                              </span>
                            )}
                          </button>

                          {currentTale.audio_url && (
                            <button
                              className="col-span-2 bg-primary-400 p-2 rounded-lg flex flex-col items-center justify-center text-xs font-medium hover:bg-primary-500 transition-colors duration-200"
                              onClick={handlePlayExistingAudio}
                              disabled={isReading || audioUrl !== null}
                            >
                              <Play className="mb-1" size={20} />
                              Aus der Erinnerung
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {error && (
                      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mt-2" role="alert">
                        <span className="block sm:inline">{error}</span>
                      </div>
                    )}

                  </div>
                )}
              </div>
            </div>

            {/* Neuer Disclaimer am Ende der Seite */}
            <div className="bg-gray-100 border-l-4 border-gray-300 text-content p-3 mt-4 text-sm" role="alert">
              <p className="font-semibold mb-1 font-heading">Hinweis:</p>
              <p>Diese App ist für Erwachsene gedacht, die Geschichten für Kinder erstellen möchten. Bitte prüfe generierte Inhalte vor dem Teilen mit Kindern.</p>
            </div>
          </div>
        </div>

        {/* Footer mit bedingtem Margin */}
        <div className={showAudioControls ? 'mb-28' : ''}>
          <Footer />
        </div>

        {/* AudioPlayer */}
        {showAudioControls && currentTale && (
          <AudioPlayer
            audioUrl={audioUrl}
            title={currentTale.title}
            onClose={handleCloseAudioControls}
            onDownload={handleDownload}
            hasStoredAudio={Boolean(currentTale.audio_url)}
          />
        )}

        {/* Modals */}
        <ProfileModal
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
        />

        <AlertModal
          isOpen={alertMessage !== null}
          message={alertMessage || ''}
          onClose={() => setAlertMessage(null)}
        />

        <SubscriptionModal
          isOpen={isSubscriptionModalOpen}
          onClose={() => setIsSubscriptionModalOpen(false)}
        />

        <FeedbackModal
          isOpen={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
        />

        {/* Transaktionshistorie */}
        <TransactionHistory 
          isOpen={showTransactionHistory}
          onClose={() => setShowTransactionHistory(false)}
        />
      </div>
    </Background>
  );
};

export default TaleBear;
