import React, { useState, useEffect } from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from './supabaseClient';
import TaleBear from './TaleBear';
import LandingPage from './LandingPage';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ResetPassword from './ResetPassword';
import AlertModal from './components/AlertModal';
import SubscriptionPage from './SubscriptionPage';
import SuccessPage from './SuccessPage';
import CanceledPage from './CanceledPage';
import AGBPage from './pages/AGBPage';
import DatenschutzPage from './pages/DatenschutzPage';
import ImpressumPage from './pages/ImpressumPage';
import { useInternetConnection } from './hooks/useInternetConnection';

export interface PasswordValidationResult {
  isValid: boolean;
  errors: string[];
}

export const validatePassword = (password: string): PasswordValidationResult => {
  const errors: string[] = [];
  
  if (password.length < 8) {
    errors.push('Mindestens 8 Zeichen');
  }
  if (!/[A-Z]/.test(password)) {
    errors.push('Mindestens ein Großbuchstabe');
  }
  if (!/[a-z]/.test(password)) {
    errors.push('Mindestens ein Kleinbuchstabe');
  }
  if (!/[0-9]/.test(password)) {
    errors.push('Mindestens eine Zahl');
  }
  // eslint-disable-next-line
  if (!/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]/.test(password)) {
    errors.push('Mindestens ein Sonderzeichen');
  }

  return {
    isValid: errors.length === 0,
    errors
  };
};

const App: React.FC = () => {
  const [session, setSession] = useState<Session | null>(null);
  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const { online, speed } = useInternetConnection(5);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (!online) {
      showAlert('Keine Internetverbindung verfügbar. Bitte überprüfe deine Verbindung.');
    } else if (speed.status === 'slow') {
      showAlert(
        `Langsame Internetverbindung erkannt. ` +
        'Dies könnte die Leistung der App beeinträchtigen.'
      );
    }
  }, [online, speed.status]);

  const showAlert = (message: string) => {
    setAlertMessage(message);
  };

  const handleLogin = async (email: string, password: string, captchaToken: string) => {
    try {
      const { error } = await supabase.auth.signInWithPassword({ 
        email, 
        password,
        options: {
          captchaToken
        }
      });
      if (error) throw error;
    } catch (error: any) {
      console.error('Fehler beim Anmelden:', error);
      let errorMessage = 'Ein unbekannter Fehler ist aufgetreten.';
      
      switch (error.code) {
        case 'email_not_confirmed':
          errorMessage = 'Bitte bestätige zuerst deine E-Mail-Adresse.';
          break;
        case 'invalid_credentials':
          errorMessage = 'Ungültige Anmeldedaten. Bitte überprüfe deine E-Mail und dein Passwort.';
          break;
        case 'user_not_found':
          errorMessage = 'Kein Benutzer mit dieser E-Mail-Adresse gefunden.';
          break;
        case 'user_banned':
          errorMessage = 'Dieser Account wurde vorübergehend gesperrt.';
          break;
        case 'too_many_requests':
          errorMessage = 'Zu viele Anmeldeversuche. Bitte versuche es später erneut.';
          break;
        case 'captcha_required':
          errorMessage = 'Bitte bestätige, dass du ein Mensch bist.';
          break;
        default:
          errorMessage = 'Ein Fehler ist aufgetreten. Bitte versuche es später erneut.';
      }
      showAlert(errorMessage);
    }
  };

  const handleSignUp = async (email: string, password: string, captchaToken: string) => {
    try {
      const { data: authData, error: signUpError } = await supabase.auth.signUp({
        email,
        password,
        options: {
          captchaToken
        }
      });

      if (signUpError) throw signUpError;
      
      if (authData.user) {
        const { error: subscriptionError } = await supabase.functions.invoke(
          'create-default-subscription',
          {
            body: { userId: authData.user.id },
          }
        );

        if (subscriptionError) {
          console.error('Fehler beim Erstellen des Standardabonnements:', subscriptionError);
          throw subscriptionError;
        }
      }

      showAlert('Bitte bestätige deine E-Mail-Adresse. Du erhältst in Kürze eine E-Mail von uns.');
    } catch (error: any) {
      console.error('Fehler bei der Registrierung:', error);
      let errorMessage = 'Ein unbekannter Fehler ist aufgetreten.';
      
      switch (error.code) {
        case 'email_exists':
          errorMessage = 'Diese E-Mail-Adresse wird bereits verwendet.';
          break;
        case 'signup_disabled':
          errorMessage = 'Die Registrierung ist derzeit deaktiviert.';
          break;
        case 'weak_password':
          errorMessage = 'Das Passwort erfüllt nicht die Sicherheitsanforderungen.';
          break;
        case 'captcha_failed':
          errorMessage = 'Captcha-Überprüfung fehlgeschlagen. Bitte versuche es erneut.';
          break;
        case 'request_timeout':
          errorMessage = 'Die Anfrage hat zu lange gedauert. Bitte versuche es erneut.';
          break;
        default:
          errorMessage = 'Ein Fehler ist bei der Registrierung aufgetreten. Bitte versuche es später erneut.';
      }
      showAlert(errorMessage);
    }
  };

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
    } catch (error: unknown) {
      console.error('Fehler beim Abmelden:', error);
      if (error instanceof Error) {
        showAlert(error.message);
      } else {
        showAlert('Ein unbekannter Fehler ist aufgetreten.');
      }
    }
  };


  if (loading) {
    return <div>Laden...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          session ? <Navigate to="/talebear" /> : <LandingPage onLogin={handleLogin} onSignUp={handleSignUp} />
        } />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/talebear" element={
          session ? <TaleBear onLogout={handleLogout} /> : <Navigate to="/" />
        } />
        <Route path="/subscription" element={<SubscriptionPage />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/canceled" element={<CanceledPage />} />
        <Route path="/agb" element={<AGBPage />} />
        <Route path="/datenschutz" element={<DatenschutzPage />} />
        <Route path="/impressum" element={<ImpressumPage />} />
      </Routes>
      <AlertModal
        isOpen={alertMessage !== null}
        message={alertMessage || ''}
        onClose={() => setAlertMessage(null)}
      />
    </Router>
  );
};

export default App;
