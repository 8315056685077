import { useState, useEffect, useCallback } from 'react';

interface ConnectionState {
  online: boolean;
  speed: {
    value: number;
    status: 'fast' | 'slow' | 'unknown';
  };
}

export const useInternetConnection = (slowThreshold: number = 5) => {
  const [connection, setConnection] = useState<ConnectionState>({
    online: navigator.onLine,
    speed: {
      value: 0,
      status: 'unknown'
    }
  });
  const [lastTestTime, setLastTestTime] = useState<number>(0);

  const measureSpeed = useCallback(async () => {
    if (!navigator.onLine) return;

    try {
      const imageUrl = 'https://ufqdrvcrcxprfcwnizxr.supabase.co/storage/v1/object/public/public/1MB_image.png';
      const startTime = performance.now();
      
      const response = await fetch(imageUrl);
      const reader = response.body?.getReader();
      const contentLength = +(response.headers?.get('content-length') ?? 0);
      
      if (!reader || !contentLength) {
        throw new Error('Konnte Testdatei nicht laden');
      }

      let receivedLength = 0; // eslint-disable-line @typescript-eslint/no-unused-vars
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        receivedLength += value.length; // eslint-disable-line @typescript-eslint/no-unused-vars
      }

      const endTime = performance.now();
      const durationInSeconds = (endTime - startTime) / 1000;
      const fileSizeInBits = contentLength * 8;
      const speedInMbps = (fileSizeInBits / 1000000) / durationInSeconds;

      setConnection(prev => ({
        ...prev,
        speed: {
          value: Number(speedInMbps.toFixed(2)),
          status: speedInMbps < slowThreshold ? 'slow' : 'fast'
        }
      }));
      
      setLastTestTime(Date.now());
    } catch (error) {
      console.error('Fehler bei der Geschwindigkeitsmessung:', error);
      setConnection(prev => ({
        ...prev,
        speed: { value: 0, status: 'unknown' }
      }));
    }
  }, [slowThreshold]);

  const checkConnection = useCallback(async () => {
    const now = Date.now();
    const timeSinceLastTest = now - lastTestTime;
    const MIN_TEST_INTERVAL = 5 * 60 * 1000; // 5 Minuten

    if (timeSinceLastTest >= MIN_TEST_INTERVAL) {
      await measureSpeed();
    }
  }, [lastTestTime, measureSpeed]);

  useEffect(() => {
    const handleOnline = () => {
      setConnection(prev => ({ ...prev, online: true }));
      checkConnection();
    };

    const handleOffline = () => {
      setConnection(prev => ({
        ...prev,
        online: false,
        speed: { value: 0, status: 'unknown' }
      }));
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkConnection();
      }
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initialer Check nur wenn noch nie getestet wurde
    if (lastTestTime === 0) {
      checkConnection();
    }

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [checkConnection, lastTestTime]);

  return {
    ...connection,
    checkConnection // Exportiere die Funktion für manuelle Tests
  };
}; 