import { useState, useEffect, useRef } from 'react';

const useAudioPlayer = () => {
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [buffered, setBuffered] = useState(0);
  const [canPlay, setCanPlay] = useState(false);

  const audioRef = useRef<HTMLAudioElement | null>(null);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const audioUrlRef = useRef<string | null>(null);
  const blobUrlRef = useRef<string | null>(null);

  useEffect(() => {
    const audio = new Audio();
    audioRef.current = audio;
    
    const handleLoadStart = () => {
      console.log('Audio loading started');
      setIsLoading(true);
      setCanPlay(false);
    };

    const handleCanPlay = () => {
      console.log('Audio can play now');
      setIsLoading(false);
      setCanPlay(true);
    };

    const handleProgress = () => {
      if (audio.buffered.length > 0) {
        setBuffered(audio.buffered.end(audio.buffered.length - 1));
      }
    };

    const handleLoadedMetadata = () => {
      if (isFinite(audio.duration)) {
        setDuration(audio.duration);
      }
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    const handleEnded = () => {
      setIsPlaying(false);
      setCurrentTime(0);
    };

    const handleError = (e: any) => {
      console.error('Audio error:', e);
      setIsLoading(false);
      setCanPlay(false);
    };

    audio.addEventListener('loadstart', handleLoadStart);
    audio.addEventListener('canplay', handleCanPlay);
    audio.addEventListener('progress', handleProgress);
    audio.addEventListener('loadedmetadata', handleLoadedMetadata);
    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('error', handleError);

    return () => {
      audio.removeEventListener('loadstart', handleLoadStart);
      audio.removeEventListener('canplay', handleCanPlay);
      audio.removeEventListener('progress', handleProgress);
      audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('error', handleError);
      
      // Cleanup Blob URLs
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      
      audio.pause();
      audio.src = '';
      setCanPlay(false);
    };
  }, []);

  useEffect(() => {
    if (!audioRef.current || !audioUrl || audioUrl === audioUrlRef.current) return;
    
    audioUrlRef.current = audioUrl;
    console.log('Setting new audio URL:', audioUrl);

    const loadAudio = async () => {
      setIsLoading(true);
      
      try {
        if (isIOS) {
          // Cleanup previous Blob URL
          if (blobUrlRef.current) {
            URL.revokeObjectURL(blobUrlRef.current);
          }

          const response = await fetch(audioUrl);
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          blobUrlRef.current = url;
          
          if (audioRef.current) {
            audioRef.current.src = url;
            audioRef.current.load();
          }
        } else {
          audioRef.current!.src = audioUrl;
          audioRef.current!.load();
        }
      } catch (error) {
        console.error('Error loading audio:', error);
        setIsLoading(false);
        setCanPlay(false);
      }
    };

    loadAudio();
  }, [audioUrl, isIOS]);

  const togglePlayPause = async () => {
    if (!audioRef.current || !canPlay) return;

    try {
      if (isPlaying) {
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        await audioRef.current.play();
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Error toggling play/pause:', error);
      setIsPlaying(false);
    }
  };

  const seek = (time: number) => {
    if (audioRef.current && canPlay) {
      audioRef.current.currentTime = time;
      setCurrentTime(time);
    }
  };

  const changeVolume = (newVolume: number) => {
    if (audioRef.current) {
      audioRef.current.volume = newVolume;
      setVolume(newVolume);
    }
  };

  return {
    isPlaying,
    currentTime,
    duration,
    volume,
    isLoading,
    buffered,
    canPlay,
    togglePlayPause,
    seek,
    setAudioUrl,
    changeVolume,
  };
};

export default useAudioPlayer;