import React from 'react';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  return (
    <footer role="contentinfo" className="mt-4">
      <div className="bg-white/60 backdrop-blur-lg border border-gray-200/60 rounded-xl py-4 px-6 max-w-sm md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center text-sm">
          <div className="mb-4 md:mb-0 text-content">
            <p>&copy; {new Date().getFullYear()} talebear.com</p>
          </div>
          <div className="flex space-x-6">
            <Link
              to="/agb"
              className="text-content hover:text-primary-600 transition-colors"
            >
              AGB
            </Link>
            <Link
              to="/datenschutz"
              className="text-content hover:text-primary-600 transition-colors"
            >
              Datenschutz
            </Link>
            <Link
              to="/impressum"
              className="text-content hover:text-primary-600 transition-colors"
            >
              Impressum
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
