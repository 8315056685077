import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

interface AlertModalProps {
  isOpen: boolean;
  message: string;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
}

const AlertModal: React.FC<AlertModalProps> = ({
  isOpen,
  message,
  onClose,
  onConfirm,
  confirmText = 'Bestätigen',
  cancelText = 'Abbrechen'
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[100]"
        >
          <motion.div
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.5, opacity: 0 }}
            className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full"
          >
            <p className="mb-4 whitespace-pre-line">{message}</p>
            <div className="flex justify-end">
              {onConfirm ? (
                <>
                  <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-200 rounded">
                    {cancelText}
                  </button>
                  <button onClick={onConfirm} className="px-4 py-2 bg-red-500 text-white rounded">
                    {confirmText}
                  </button>
                </>
              ) : (
                <button onClick={onClose} className="px-4 py-2 bg-blue-500 text-white rounded">
                  OK
                </button>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default AlertModal;