import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { getImpressumUrl } from '../supabaseClient';

const ImpressumPage: React.FC = () => {
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const url = await getImpressumUrl();
        const response = await fetch(url);
        const text = await response.text();
        setContent(text);
      } catch (error) {
        console.error('Fehler beim Laden des Impressums:', error);
      }
    };

    fetchContent();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex-grow">
        <div className="max-w-4xl mx-auto p-6">
          <Link 
            to="/" 
            className="inline-flex items-center text-emerald-600 hover:text-emerald-700 mb-6"
          >
            <ArrowLeft className="mr-2" size={20} />
            Zurück zur App
          </Link>
          <h1 className="text-3xl font-bold mb-6">Impressum</h1>
          <div 
            className="prose prose-lg max-w-none"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ImpressumPage;
