import React, { useState, useEffect } from 'react';
import { X, EyeIcon, EyeOffIcon } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { supabase } from './supabaseClient';
import AlertModal from './components/AlertModal';
import { validatePassword, PasswordValidationResult } from './App';

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState<PasswordValidationResult>({ isValid: false, errors: [] });
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          setEmail(user.email || '');
        }
      } catch (error) {
        console.error('Fehler beim Laden der Benutzerdaten:', error);
        setAlertMessage('Fehler beim Laden der Benutzerdaten');
        setIsAlertOpen(true);
      } finally {
        setLoading(false);
      }
    };

    if (isOpen) {
      loadUserData();
    }
  }, [isOpen]);

  const handlePasswordChange = async () => {
    if (!passwordValidation.isValid) {
      setAlertMessage('Bitte überprüfe die Passwortanforderungen.');
      setIsAlertOpen(true);
      return;
    }

    try {
      setLoading(true);
      const { error } = await supabase.rpc('change_user_password', {
        current_password: oldPassword,
        new_password: newPassword
      });

      if (error) throw error;

      setAlertMessage('Passwort erfolgreich geändert');
      setIsAlertOpen(true);
      setNewPassword('');
      setOldPassword('');
    } catch (error) {
      console.error('Fehler beim Ändern des Passworts:', error);
      setAlertMessage('Fehler beim Ändern des Passworts: ' + (error as Error).message);
      setIsAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation !== email) {
      setAlertMessage('Bitte gebe deine E-Mail-Adresse ein, um das Konto zu löschen');
      setIsAlertOpen(true);
      return;
    }
    setIsDeleteConfirmationOpen(true);
  };

  const confirmDeleteAccount = async () => {
    setLoading(true);
    try {
      // Zuerst die OAuth-Verbindungen löschen (falls vorhanden)
      const { data: { user } } = await supabase.auth.getUser();
      if (user?.app_metadata?.provider === 'google') {
        await supabase.auth.signOut(); // Dies entfernt die OAuth-Sitzung
      }

      // Dann das Konto löschen
      const { error } = await supabase.rpc('delete_user');
      if (error) throw error;

      setAlertMessage('Ihr Konto wurde erfolgreich gelöscht');
      setIsAlertOpen(true);
      onClose();
      // Hier sollten Sie den Benutzer zur Startseite weiterleiten
      window.location.href = '/';
    } catch (error) {
      console.error('Fehler beim Löschen des Kontos:', error);
      setAlertMessage('Fehler beim Löschen des Kontos: ' + (error as Error).message);
      setIsAlertOpen(true);
    } finally {
      setLoading(false);
      setIsDeleteConfirmationOpen(false);
    }
  };

  const getLabelStyle = (value: string) => {
    return `absolute left-2 transition-all duration-200 ${
      value ? 'text-xs -top-2 bg-white px-1' : 'text-gray-500 top-1/2 -translate-y-1/2'
    }`;
  };

  if (!isOpen) return null;

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-auto p-4"
          >
            <motion.div 
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: "spring", duration: 0.5 }}
              className="bg-white rounded-lg p-4 sm:p-6 md:p-8 max-w-md w-full max-h-[90vh] overflow-auto relative"
            >
              <button 
                onClick={onClose}
                className="absolute top-2 right-2 sm:top-4 sm:right-4 text-content-light hover:text-content-dark"
                aria-label="Schließen"
              >
                <X size={24} />
              </button>
              <h2 className="text-2xl font-bold mb-4">Profil verwalten</h2>
              {loading ? (
                <p>Laden...</p>
              ) : (
                <div>
                  <p className="mb-4"><strong>E-Mail:</strong> {email}</p>
                  
                  <div className="mb-6">
                    <h3 className="font-bold mb-2">Passwort ändern</h3>
                    <form>
                      <div className="relative mb-4">
                        <input
                          type={showOldPassword ? "text" : "password"}
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          className="border p-2 w-full pr-10 h-12"
                          id="old-password"
                          name="old-password"
                          autoComplete="current-password"
                        />
                        <label htmlFor="old-password" className={getLabelStyle(oldPassword)}>
                          Aktuelles Passwort
                        </label>
                        <button
                          type="button"
                          onClick={() => setShowOldPassword(!showOldPassword)}
                          className="absolute right-2 top-1/2 -translate-y-1/2"
                        >
                          {showOldPassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
                        </button>
                      </div>
                      <div className="relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          value={newPassword}
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            setPasswordValidation(validatePassword(e.target.value));
                          }}
                          className="border p-2 w-full pr-10 h-12"
                          id="new-password"
                          name="new-password"
                          autoComplete="new-password"
                        />
                        <label htmlFor="new-password" className={getLabelStyle(newPassword)}>
                          Neues Passwort
                        </label>
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-2 top-1/2 -translate-y-1/2"
                        >
                          {showPassword ? <EyeOffIcon size={20} /> : <EyeIcon size={20} />}
                        </button>
                      </div>
                    </form>
                    {newPassword && (
                      <div className="mb-4">
                        <h3 className="text-sm font-semibold mb-2">Passwortanforderungen:</h3>
                        <ul className="text-xs">
                          {['Mindestens 8 Zeichen', 'Mindestens ein Großbuchstabe', 'Mindestens ein Kleinbuchstabe', 'Mindestens eine Zahl', 'Mindestens ein Sonderzeichen'].map((requirement, index) => (
                            <li key={index} className={passwordValidation.errors.includes(requirement) ? 'text-red-500' : 'text-green-500'}>
                              {requirement}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                    <button 
                      onClick={handlePasswordChange} 
                      className="bg-secondary-500 text-white px-4 py-2 rounded mt-4 hover:bg-secondary-600"
                      disabled={!passwordValidation.isValid || !oldPassword}
                    >
                      Passwort ändern
                    </button>
                  </div>
                  
                  <div className="mt-8">
                    <h3 className="font-bold mb-2 text-red-600">Konto löschen</h3>
                    <p className="mb-2">Diese Aktion kann nicht rückgängig gemacht werden. Bitte gebe deine E-Mail-Adresse ein, um zu bestätigen.</p>
                    <div className="relative mb-4">
                      <input
                        type="email"
                        value={deleteConfirmation}
                        onChange={(e) => setDeleteConfirmation(e.target.value)}
                        className="border p-2 w-full pr-10 h-12"
                        id="delete-confirmation"
                      />
                      <label htmlFor="delete-confirmation" className={getLabelStyle(deleteConfirmation)}>
                        E-Mail zur Bestätigung
                      </label>
                    </div>
                    <button 
                      onClick={handleDeleteAccount} 
                      className="bg-red-500 text-white px-4 py-2 rounded"
                    >
                      Konto löschen
                    </button>
                  </div>
                </div>
              )}
            </motion.div>
          </motion.div>

          {/* Alert Modal für allgemeine Nachrichten */}
          <AlertModal
            isOpen={isAlertOpen}
            message={alertMessage}
            onClose={() => setIsAlertOpen(false)}
          />

          {/* Alert Modal für Löschbestätigung */}
          <AlertModal
            isOpen={isDeleteConfirmationOpen}
            message="Bist du sicher, dass du dein Konto löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
            onClose={() => setIsDeleteConfirmationOpen(false)}
            onConfirm={confirmDeleteAccount}
            confirmText="Löschen"
            cancelText="Abbrechen"
          />
        </>
      )}
    </AnimatePresence>
  );
};

export default ProfileModal;
