import { createClient } from '@supabase/supabase-js'
import { filterContent } from './utils/contentFilter'; // Fügen Sie diese Zeile hinzu
import md5 from 'md5';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Supabase URL or Anon Key is missing. Check your .env file.')
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: true
  }
})

// Fügen Sie diese Typdefinitionen hinzu
export interface Tale {
  id: string;
  user_id: string;
  title: string;
  content: string;
  audio_url?: string;
  created_at: string;
  content_hash?: string;
  image_url?: string;
}

// Fügen Sie diese Funktionen hinzu
export const saveTale = async (tale: Omit<Tale, 'id' | 'user_id' | 'created_at'>): Promise<Tale> => {
  const { data: { user } } = await supabase.auth.getUser();
  if (!user) throw new Error('Nicht authentifiziert');

  const { data, error } = await supabase
    .from('tales')
    .insert({ ...tale, user_id: user.id })
    .select('id, user_id, title, content, audio_url, created_at, content_hash, image_url')
    .single();

  if (error) {
    if (error.message.includes('check_stories_limit')) {
      throw new Error('Geschichten-Limit erreicht. Bitte upgrade dein Abonnement oder warte bis es wieder aufgefüllt wird.');
    }
    throw error;
  }

  if (!data) throw new Error('Keine Daten zurückgegeben');

  return {
    id: data.id,
    user_id: data.user_id,
    title: data.title,
    content: data.content,
    audio_url: data.audio_url,
    created_at: data.created_at,
    image_url: data.image_url
  };
};

export const getTales = async (userId: string): Promise<Tale[]> => {
  const { data, error } = await supabase
    .from('tales')
    .select('*')
    .eq('user_id', userId)
    .order('created_at', { ascending: false });

  if (error) throw error;

  // Filter the content of each tale
  const filteredTales = data.map(tale => ({
    ...tale,
    title: filterContent(tale.title),
    content: filterContent(tale.content)
  }));

  return filteredTales;
};

// Neue Funktion zum Aktualisieren des gefilterten Inhalts auf dem Server
export const updateFilteredTaleContent = async (taleId: string, title: string, content: string) => {
  const { data, error } = await supabase
    .from('tales')
    .update({ title, content })
    .eq('id', taleId)
    .select();

  if (error) throw error;
  return data[0];
};

export const updateTale = async (tale: Partial<Tale>) => {
  const { data, error } = await supabase
    .from('tales')
    .update({
      title: tale.title,
      content: tale.content,
      audio_url: tale.audio_url,
      image_url: tale.image_url
    })
    .eq('id', tale.id)
    .select('id, user_id, title, content, audio_url, created_at, content_hash, image_url');

  if (error) throw error;
  return data[0];
};

export const deleteTale = async (taleId: string) => {
  const { error } = await supabase
    .from('tales')
    .delete()
    .eq('id', taleId);

  if (error) throw error;
};

export const uploadAudio = async (userId: string, taleId: string, audioBlob: Blob) => {
  const filePath = `${userId}/${taleId}.mp3`;
  const { data, error } = await supabase.storage
    .from('tale_audio')
    .upload(filePath, audioBlob, {
      contentType: 'audio/mpeg',
    });

  if (error) throw error;
  return data.path;
};

export const getAudioUrl = async (userId: string, taleId: string): Promise<string | null> => {
  try {
    const { data: tale } = await supabase
      .from('tales')
      .select('audio_url')
      .eq('id', taleId)
      .single();

    if (tale?.audio_url) {
      // Passe die URL für die lokale Entwicklung an
      if (process.env.REACT_APP_SUPABASE_URL?.includes('localhost')) {
        return tale.audio_url.replace(/^https?:\/\/[^/]+/, 'http://localhost:54321');
      }
      return tale.audio_url;
    }
    return null;
  } catch (error) {
    console.error('Error getting audio URL:', error);
    return null;
  }
};

export const getBadWords = async (): Promise<string[]> => {
  const { data, error } = await supabase.rpc('get_bad_words');
  if (error) throw error;
  return data.map((item: { word: string }) => item.word);
};

export const getAGBUrl = async (): Promise<string> => {
  try {
    const { data } = await supabase.storage
      .from('documents')
      .getPublicUrl('agb.txt');

    if (!data) {
      console.error('Keine Daten zurückgegeben');
      throw new Error('Keine Daten zurückgegeben');
    }

    console.log('Public URL:', data.publicUrl);
    return data.publicUrl;
  } catch (error) {
    console.error('Fehler in getAGBUrl:', error);
    throw error;
  }
};

export const getPrivacyUrl = async (): Promise<string> => {
  try {
    const { data } = await supabase.storage
      .from('documents')
      .getPublicUrl('privacy.txt');

    if (!data) {
      console.error('Keine Daten zurückgegeben');
      throw new Error('Keine Daten zurückgegeben');
    }

    console.log('Public URL:', data.publicUrl);
    return data.publicUrl;
  } catch (error) {
    console.error('Fehler in getPrivacyUrl:', error);
    throw error;
  }
};

export const resetPassword = async (email: string, captchaToken: string): Promise<void> => {
  const redirectTo = process.env.NODE_ENV === 'production'
    ? 'https://talebear-git-preview-floyd-schauers-projects.vercel.app/reset-password'
    : `${window.location.origin}/reset-password`;

  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo,
    captchaToken
  });
  if (error) throw error;
};

// Neue Typdefinitionen
export interface UserSubscription {
  tier: 'free' | 'basic' | 'premium' | 'pro' | 'admin';
  endDate: string | null; // Ändern Sie dies zu string | null
}

export interface UserCredits {
  userId: string;
  credits: number;
  lastRefillDate: string;
}

// Neue Funktionen für das Creditsystem
export const getUserSubscription = async (userId: string): Promise<UserSubscription | null> => {
  try {
    const { data: subscriptionData, error: subscriptionError } = await supabase
      .from('user_subscriptions')
      .select('tier, end_date')
      .eq('user_id', userId)
      .single();

    if (subscriptionError) {
      console.error('Fehler beim Abrufen des Abonnements:', subscriptionError);
      // Wenn kein Abonnement gefunden wurde, erstellen wir ein kostenloses
      if (subscriptionError.code === 'PGRST116') {
        console.log('Kein Abonnement gefunden, erstelle kostenloses Abonnement');
        await createDefaultSubscription(userId);
        return { tier: 'free', endDate: null };
      }
      throw subscriptionError;
    }

    return {
      tier: subscriptionData.tier as 'free' | 'basic' | 'premium' | 'pro' | 'admin',
      endDate: subscriptionData.end_date
    };
  } catch (error) {
    console.error('Unerwarteter Fehler:', error);
    return null;
  }
};

export const getUserCredits = async (userId: string): Promise<number> => {
  const { data, error } = await supabase
    .from('user_credits')
    .select('credits')
    .eq('user_id', userId)
    .single();

  if (error) throw error;
  return data?.credits || 0;
};

export const updateUserCredits = async (userId: string, newCredits: number): Promise<void> => {
  const { error } = await supabase
    .from('user_credits')
    .upsert({
      user_id: userId,
      credits: newCredits,
      last_refill_date: new Date().toISOString()
    });

  if (error) throw error;
};

export const refillCredits = async (userId: string): Promise<number> => {
  const { data, error } = await supabase
    .from('user_credits')
    .select('credits')
    .eq('user_id', userId)
    .single();

  if (error) throw error;
  return data.credits;
};

export const shouldRefillCredits = async (userId: string): Promise<boolean> => {
  const { data, error } = await supabase
    .from('user_credits')
    .select('last_refill_date')
    .eq('user_id', userId)
    .single();

  if (error) {
    if (error.code === 'PGRST116') {
      // Kein Eintrag gefunden, also sollten wir auffüllen
      return true;
    }
    throw error;
  }

  if (!data.last_refill_date) {
    return true;
  }

  const lastRefillDate = new Date(data.last_refill_date);
  const now = new Date();
  const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

  return lastRefillDate < oneDayAgo;
};

// Fügen Sie diese Funktion zur supabaseClient.ts hinzu
export const signInWithGoogle = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
  });

  if (error) throw error;
};

// Bestehenden Code durch diese neue Version ersetzen
export const createDefaultSubscription = async (userId: string): Promise<void> => {
  try {
    const { data, error } = await supabase.functions.invoke('create-default-subscription', {
      body: { userId },
    });

    if (error) {
      console.error('Fehler beim Erstellen des Standardabonnements:', error);
      throw error;
    }

    console.log('Standardabonnement erstellt:', data);
  } catch (error) {
    console.error('Fehler beim Aufrufen der Edge Function:', error);
    throw error;
  }
};

export async function getCreditsPerRequest(requestType: string): Promise<number> {
  console.log('Lade Credits pro Anfrage:', requestType);
  const { data, error } = await supabase
    .from('credits_per_request')
    .select('credits')
    .eq('request_type', requestType)
    .single();

  if (error) {
    console.error('Fehler beim Abrufen der Credits pro Anfrage:', error);
    throw error;
  }

  return data?.credits || 0;
}



export const getStoriesLimit = async (tier: string): Promise<number> => {
  const { data, error } = await supabase
    .from('stories_per_subscription')
    .select('num_stories')
    .eq('tier', tier)
    .single();

  if (error) {
    console.error('Fehler beim Abrufen des Story-Limits:', error);
    return 0;
  }

  return data?.num_stories || 0;
};

// Fügen Sie diese neue Schnittstelle hinzu
export interface StoryLength {
  type: string;
  label: string;
  credits: number;
}

// Fügen Sie diese neue Funktion hinzu
export const getStoryLengthOptions = async (): Promise<StoryLength[]> => {
  const { data, error } = await supabase
    .from('credits_per_request')
    .select('*')
    .like('request_type', 'generate-story%')
    .order('credits');

  if (error) throw error;

  return data.map(item => ({
    type: item.request_type,
    label: getLengthLabel(item.request_type),
    credits: item.credits
  }));
};

// Hilfsfunktion für die Labels
const getLengthLabel = (type: string): string => {
  switch (type) {
    case 'generate-story':
      return 'Kurz';
    case 'generate-story-long':
      return 'Mittel';
    case 'generate-story-extra-long':
      return 'Lang';
    default:
      return 'Unbekannt';
  }
};

export async function getImpressumUrl() {
  const { data, error } = await supabase
    .storage
    .from('public')
    .createSignedUrl('impressum.txt', 3600); // URL ist 1 Stunde gültig

  if (error) throw error;
  return data.signedUrl;
}

// Funktion zum Berechnen des Hashes
export const calculateContentHash = (title: string, content: string): string => {
  return md5(title + content);
};
