import React from 'react';
import { Link } from 'react-router-dom';

const CanceledPage: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Abonnement-Vorgang abgebrochen</h1>
      <p className="mb-4">Du hast den Abonnement-Vorgang abgebrochen. Keine Sorge, es wurden keine Änderungen vorgenommen.</p>
      <Link to="/subscription" className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-200">
        Zurück zur Abonnement-Seite
      </Link>
    </div>
  );
};

export default CanceledPage;
