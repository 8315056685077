import React, { useEffect, useState } from 'react';
import { Play, Pause, XIcon, Download, Volume2, Loader } from 'lucide-react';
import useAudioPlayer from '../hooks/useAudioPlayer';

interface AudioPlayerProps {
  audioUrl: string | null;
  title: string;
  onClose: () => void;
  onDownload: () => void;
  hasStoredAudio: boolean;
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl, title, onClose, onDownload, hasStoredAudio }) => {
  const { 
    isPlaying, 
    currentTime, 
    duration, 
    volume, 
    isLoading,
    buffered,
    canPlay,
    togglePlayPause, 
    seek, 
    setAudioUrl, 
    changeVolume 
  } = useAudioPlayer();
  const [isIOS, setIsIOS] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    const checkIsIOS = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };

    setIsIOS(checkIsIOS());

    if (audioUrl) {
      setAudioUrl(audioUrl);
    }
  }, [audioUrl, setAudioUrl]);

  const truncateTitle = (title: string, maxLength: number = 35) => {
    return title.length > maxLength ? title.substring(0, maxLength) + '...' : title;
  };

  const handleSliderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(e.target.value);
    seek(newTime);
  };

  const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    changeVolume(newVolume);
  };

  const formatTime = (time: number): string => {
    if (!isFinite(time) && !duration) {
      return 'wird geladen...';
    }
    
    const timeToFormat = isFinite(time) ? time : 0;
    const minutes = Math.floor(timeToFormat / 60);
    const seconds = Math.floor(timeToFormat % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    try {
      await onDownload();
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-300 text-primary-700 p-4 shadow-lg z-50">
      <div className="max-w-7xl mx-auto relative">
        <div className="flex justify-between items-center">
          <h2 className="text-lg text-primary-600 font-bold">
            {truncateTitle(title)}
          </h2>
        </div>
        <button
          onClick={onClose}
          className="absolute top-0 right-0 text-content hover:text-content-dark"
        >
          <XIcon size={20} />
        </button>
        <div className="flex flex-col md:flex-row justify-between items-center pt-6 md:pt-0">
          <div className="mb-2 md:mb-0 md:w-1/3">
            <p className="font-bold text-content-light">Hinweis:</p>
            <p className="text-content-light">Diese Stimme wurde von einer künstlichen Intelligenz generiert.</p>
          </div>
          <div className="flex flex-col items-center w-full md:w-2/3 md:pl-4">
            <div className="w-full relative">
              <input
                type="range"
                min={0}
                max={duration || 100}
                step={0.01}
                value={currentTime}
                onChange={handleSliderChange}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
              />
              {isLoading && duration > 0 && (
                <div 
                  className="absolute top-0 left-0 h-2 bg-gray-400 rounded-lg opacity-50"
                  style={{ width: `${(buffered / (duration || 1)) * 100}%` }}
                />
              )}
            </div>
            <div className="flex justify-between w-full text-xs mt-1">
              <span>{formatTime(currentTime)}</span>
              <span>{duration ? formatTime(duration) : 'wird geladen...'}</span>
            </div>
            <div className="flex items-center space-x-4 mt-2">
              <button
                onClick={togglePlayPause}
                disabled={isLoading || !canPlay}
                className="bg-secondary-500 hover:bg-secondary-600 text-white p-2 rounded-full transition-colors duration-200 disabled:opacity-50"
              >
                {isLoading ? (
                  <Loader className="animate-spin" size={24} />
                ) : isPlaying ? (
                  <Pause size={24} />
                ) : (
                  <Play size={24} />
                )}
              </button>
              <button
                onClick={handleDownload}
                disabled={!hasStoredAudio || isDownloading}
                className="bg-secondary-500 hover:bg-secondary-600 text-white p-2 rounded-full transition-colors duration-200 disabled:opacity-50"
              >
                {isDownloading ? (
                  <Loader className="animate-spin" size={24} />
                ) : (
                  <Download size={24} />
                )}
              </button>
              {!isIOS && (
                <div className="flex items-center">
                  <Volume2 size={20} className="mr-2" />
                  <input
                    type="range"
                    min={0}
                    max={1}
                    step={0.01}
                    value={volume}
                    onChange={handleVolumeChange}
                    className="w-24 h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;